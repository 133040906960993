import cadex from '@cadexchanger/web-toolkit'
import { SceneDataStore } from '../store/SceneDataStore'
import { PaintEntityVisitor } from '../visitors/PaintEntityVisitor'

export class PaintContextMenuHandler extends cadex.ModelPrs_ContextMenuHandler {
  private onSelectionChanged: (
    name: string,
    position: { x: number; y: number },
    shapeId: string,
    shapeName: string,
    shapeType: string
  ) => void
  private scene: cadex.ModelPrs_Scene

  public color: cadex.ModelData_ColorObject
  private sceneStore: SceneDataStore

  constructor(
    scene: cadex.ModelPrs_Scene,
    sceneStore: SceneDataStore,
    onSelectionChanged?: (
      name: string,
      position: { x: number; y: number },
      shapeId: string,
      shapeName: string,
      shapeType: string
    ) => void
  ) {
    super()

    this.scene = scene
    this.onSelectionChanged = onSelectionChanged
    this.sceneStore = sceneStore
    // this.color = new cadex.ModelData_ColorObject(1, 0, 0, 1)
  }

  private colorPickerTry = 0
  async contextMenu(event: cadex.ModelPrs_PointerInputEvent): Promise<boolean> {
    await this.scene.update()
    const position = event.point.position

    const pickResult = this.scene.selectionManager.pickFromViewport(
      position.x,
      position.y,
      event.viewport
    )

    if (pickResult) {
      const pickedEntityVisitor = new PaintEntityVisitor(
        this.color,
        this.sceneStore
      )

      pickResult.pickedEntity.accept(pickedEntityVisitor)

      if (pickedEntityVisitor.shapeType === 'FACE') {
        ++this.colorPickerTry
        if (this.colorPickerTry < 3) {
          console.warn('color picker not applicable on face, trying again')
          return this.contextMenu(event)
        } else {
          this.colorPickerTry = 0
          console.error('color picker failed')
          return false
        }
      } else {
        this.colorPickerTry = 0
      }

      await this.scene.update()

      // this.onSelectionChanged?.(
      //   pickedEntityVisitor.formattedEntity,
      //   position,
      //   pickedEntityVisitor.shapeId.toString(),
      //   pickedEntityVisitor.shapeName,
      //   pickedEntityVisitor.shapeType
      // )
    } else {
      console.info('no shape selected')
    }

    return true
  }
}
