import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useEffect, useRef } from 'react'
import { useAppSelector } from 'store/configureStore'
import { ModelController } from './manager/ModelController'
import { ModelContextMenu } from './tools/ModelContextMenu'
import { PaintContextMenu } from './tools/PaintContextMenu'
import { ViewerTools } from './tools/ViewerTools'

type Props = {
  bomItemPointer: BomItemPointer
  show3DOr2D: '3D' | '2D'
  modelController: ModelController
}

export const BomItemModelViewer = (props: Props) => {
  const fileName = useAppSelector(
    props.show3DOr2D === '3D'
      ? projectSelectors.bomItem3DPathSelector(props.bomItemPointer)
      : projectSelectors.bomItem2DPathSelector(props.bomItemPointer)
  )

  const modelElementRef = useRef<HTMLDivElement>(undefined)

  useEffect(() => {
    if (!fileName) return

    if (modelElementRef.current) {
      props.modelController.init(modelElementRef.current)

      props.show3DOr2D === '3D'
        ? props.modelController.load3DModel(props.bomItemPointer, fileName)
        : props.modelController.load2DModel(props.bomItemPointer, fileName)
    }
  }, [fileName, props.bomItemPointer, props.modelController, props.show3DOr2D])

  if (!fileName) {
    return null
  }

  return (
    <div
      style={{
        width: '100%',
        height: 'calc(100% - 52px)',
        position: 'relative',
      }}
    >
      <ViewerTools
        bomItemPointer={props.bomItemPointer}
        viewController={props.modelController}
      />
      <PaintContextMenu modelViewer={props.modelController} />
      <ModelContextMenu modelViewer={props.modelController} />
      <div
        id={`model-canvas-${props.show3DOr2D}`}
        ref={modelElementRef}
        style={{
          width: '100%',
          height: '100%',
        }}
      />
    </div>
  )
}
