import { Typography, TypographyProps } from '@mui/material'
import { TOptions } from 'i18next'
import { useTranslation } from 'react-i18next'
import { useLocalizationKey } from './useLocalizationKey'

type Props = TypographyProps<'span' | 'p' | 'h5' | 'h6'> & {
  translationKey: string
  translationDefaultValue?: string
  options?: TOptions
  ns?: string[]
  component?: string
  children?: string
  suffix?: string
  html?: boolean
  href?: string
}

export const LocalizedTypography = (props: Props) => {
  const {
    translationKey,
    ns,
    translationDefaultValue: defaultValue,
    options,
    suffix,
    children,
    prefix,
    ...typographyProps
  } = props

  const { namespace, tKey } = useLocalizationKey(translationKey, ns?.[0])

  const { t } = useTranslation(namespace)

  typographyProps.variant = typographyProps.variant || 'inherit'

  if (props.html) {
    return (
      <Typography
        component={props.component || 'span'}
        dangerouslySetInnerHTML={{
          __html: t(translationKey, options).toString(),
        }}
        {...typographyProps}
      />
    )
  } else {
    return (
      <Typography component={props.component || 'span'} {...typographyProps}>
        {`${prefix || ''}${t(
          tKey,
          options ?? { defaultValue: children ?? defaultValue }
        )}${suffix ?? ''}`}
      </Typography>
    )
  }
}
