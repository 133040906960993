import { BomItemType } from 'model/Project/BoMItemRow'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'
import { BomItemPointer } from '../../../../model/Project/BomItemPointer'

export const bomItemFileNameSelector =
  (bomItemPointer: BomItemPointer) => (state: RootAppState) => {
    const bomItem = bomItemSelector(state, bomItemPointer)

    if (!bomItem) {
      return null
    }

    if (
      bomItem?.type === BomItemType.materialHeader ||
      bomItem?.type === BomItemType.routingHeader ||
      bomItem?.type === BomItemType.project
    ) {
      throw Error(`${bomItem?.type} does not have file name`)
    }

    return bomItem?.type === BomItemType.assemblyType
      ? bomItem.originalFileName
      : bomItem.originalFileName || ''
  }
