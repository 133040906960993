/* eslint-disable @typescript-eslint/no-unused-vars */
import cadex, {
  ModelData_BRepRepresentation,
  ModelData_PolyRepresentation,
} from '@cadexchanger/web-toolkit'
import { SceneDataStore } from '../store/SceneDataStore'
import { Rh24SceneNode } from '../store/types/Rh24SceneNode'

export class FacesDataVisitor extends cadex.ModelData_SceneGraphElementVisitor {
  private store: SceneDataStore
  constructor(store: SceneDataStore) {
    super()

    this.store = store
  }

  visitAssemblyEnter(
    theAssembly: cadex.ModelData_Assembly
  ): boolean | Promise<boolean> {
    return true
  }
  visitAssemblyLeave(
    theAssembly: cadex.ModelData_Assembly
  ): void | Promise<void> {
    return
  }
  visitInstanceEnter(
    theInstance: cadex.ModelData_Instance
  ): boolean | Promise<boolean> {
    return true
  }
  visitInstanceLeave(
    theInstance: cadex.ModelData_Instance
  ): void | Promise<void> {
    return
  }
  async visitPart(thePart: cadex.ModelData_Part): Promise<void> {
    const sceneNodes = this.store.getSceneNodeItem(thePart.uuid.toString())

    if (sceneNodes) {
      const faceDataVisitor = new FaceDataVisitor(sceneNodes[0], this.store)

      if (sceneNodes[0].sceneGraphElement instanceof cadex.ModelData_Part) {
        const rep = sceneNodes[0].sceneGraphElement.representation(
          cadex.ModelData_RepresentationMask.ModelData_RM_BRep
        )
        await rep.accept(faceDataVisitor)
      }
    }
  }
}

/**
 * Visitor to get all the faces data from the model
 */
export class FaceDataVisitor extends cadex.ModelData_RepresentationVisitor {
  public sceneNodeFactory = new cadex.ModelPrs_SceneNodeFactory()
  private parentNode: Rh24SceneNode
  private sceneStore: SceneDataStore

  constructor(parentNode: Rh24SceneNode, sceneStore: SceneDataStore) {
    super()
    this.parentNode = parentNode
    this.sceneStore = sceneStore
  }

  data: Rh24SceneNode[] = []

  async visitBRepRepresentation(brepRep: ModelData_BRepRepresentation) {
    const bodyList = await brepRep.bodyList()

    for (let i = 0; i < bodyList.size(); i++) {
      for (const shape of await brepRep.subshapes()) {
        if (shape.type === cadex.ModelData_ShapeType.Face) {
          const shapeId = brepRep.shapeId(shape)
          const existentNode = this.sceneStore.getSceneNodeItem(
            shapeId.toString()
          )

          if (existentNode) {
            // console.log('existentNode')
            existentNode.forEach((node) => {
              node.parentId = this.parentNode.elementId
            })
          } else {
            const faceNode = this.sceneStore.createFromRepresentation(
              brepRep,
              shape
            )

            faceNode.parentId = this.parentNode.elementId
            faceNode['parentNode'] = this.parentNode

            this.sceneStore.addToSceneMap(faceNode)
          }
        }
      }
    }

    return
  }
  visitPolyRepresentation(
    thePolyRep: ModelData_PolyRepresentation
  ): void | Promise<void> {
    return
  }
}
