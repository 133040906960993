import { BomItemType } from 'model/Project/BoMItemRow'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'
import { BomItemPointer } from '../../../../model/Project/BomItemPointer'

export const bomItemExternalUrlSelector =
  (bomItemPointer: BomItemPointer) => (state: RootAppState) => {
    const projectId = state.project.activeProject?.id

    if (bomItemPointer.id === projectId) {
      return state.project.activeProject.externalUrl
    }

    const bomItem = bomItemSelector(state, bomItemPointer)

    if (
      bomItem?.type === BomItemType.materialHeader ||
      bomItem?.type === BomItemType.routingHeader
    ) {
      throw Error('material header does not have external url')
    }

    return bomItem?.type === BomItemType.assemblyType
      ? bomItem.externalUrl
      : bomItem.externalUrl
  }
