import { BomItemType } from 'model/Project/BoMItemRow'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'
import { BomItemPointer } from '../../../../model/Project/BomItemPointer'

export const bomItemCommentSelector =
  (bomItemPointer: BomItemPointer) => (state: RootAppState) => {
    if (!bomItemPointer || !state.project) {
      return null
    }

    if (bomItemPointer.id === state.project.activeProject?.id) {
      return state.project.activeProject.comment
    }

    const bomItem = bomItemSelector(state, bomItemPointer)

    switch (bomItem?.type) {
      case BomItemType.assemblyType: {
        return bomItem.comment
      }
      case BomItemType.partType:
      case BomItemType.partInstance:
      case BomItemType.assemblyInstance: {
        return bomItem.comment
      }
      default: {
        return null
      }
    }
  }

export const bomItemHasComment =
  (bomItemPointer: BomItemPointer) => (state: RootAppState) => {
    return bomItemCommentSelector(bomItemPointer)(state)?.length > 0
  }
