import _ from 'lodash'

export type ConnectionStatus =
  | 'online'
  | 'offline'
  | 'disconnected'
  | 'reconnecting'
  | 'connecting'

export enum ConnectionActionTypes {
  SetOnlineStatus = 'connection/set-online-status',
  SetError = 'connection/set-error',
  AddGroup = 'connection/add-update-group',
  RemoveGroup = 'connection/remove-update-group',
}

interface SetConnectionStatus {
  type: ConnectionActionTypes.SetOnlineStatus
  connectionState: ConnectionStatus
  error?: Error
  retryDescription?: string
  retryCount: number
}

interface SetError {
  type: ConnectionActionTypes.SetError
  error?: Error
}

interface AddGroup {
  type: ConnectionActionTypes.AddGroup
  group: string
}

interface RemoveGroup {
  type: ConnectionActionTypes.RemoveGroup
  group: string
}

export type ConnectionActions =
  | SetConnectionStatus
  | SetError
  | AddGroup
  | RemoveGroup

export const SetConnectionStatus = (
  connectionState: ConnectionStatus,
  error?: Error,
  retryDescription?: string,
  retryCount = 0
) => ({
  type: ConnectionActionTypes.SetOnlineStatus,
  connectionState,
  error,
  retryDescription,
  retryCount,
})

export const SetError = (error?: Error) => ({
  type: ConnectionActionTypes.SetError,
  error,
})

export const AddGroup = (group: string) => ({
  type: ConnectionActionTypes.AddGroup,
  group,
})

export const RemoveGroup = (group: string) => ({
  type: ConnectionActionTypes.RemoveGroup,
  group,
})

export type ConnectionState = {
  status: ConnectionStatus
  error?: Error
  retryDescription?: string
  groups: string[]
  retryCount: number
}

const initialState: ConnectionState = {
  status: 'offline',
  error: undefined,
  groups: [],
  retryCount: 0,
}

export const ConnectionReducer = (
  state = initialState,
  action: ConnectionActions
): ConnectionState => {
  switch (action.type) {
    case ConnectionActionTypes.SetError: {
      return {
        ...state,
        error: action.error,
        retryCount: 0,
      }
    }
    case ConnectionActionTypes.SetOnlineStatus: {
      return {
        ...state,
        status: action.connectionState,
        error: action.error,
        retryDescription: action.retryDescription,
        retryCount: action.retryCount,
      }
    }
    case ConnectionActionTypes.AddGroup: {
      return {
        ...state,
        groups: _.uniq([...state.groups, action.group]),
      }
    }
    case ConnectionActionTypes.RemoveGroup: {
      return {
        ...state,
        groups: state.groups.filter((x) => x !== action.group),
      }
    }
    default:
      return state
  }
}
