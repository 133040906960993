import { fetchProject } from 'features/BillOfMaterials/store/asyncActions/fetchProject'
import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import { bomItemSelectorFunc } from 'features/BillOfMaterials/store/selectors/bomItemSelector'
import { WorkingStepWithData } from 'features/WorkingSteps/AdditionalWorkingStepsV2/store/AdditionalWorkingStepsReducer'
import {
  AdditionalWorkingStepFormConfiguration,
  AdditionalWorkingStepFormModelBuilder,
} from 'model/AdditionalWorkingStep/AdditionalWorkingStepForm'
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import {
  AdditionalWorkingStepDto,
  ResourceWorkingStepManualDiscountArgs,
  ResourceWorkingStepManualSurchargeArgs,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ProjectState } from 'store/Project/ProjectTypes'
import { store } from 'store/configureStore'
import { WorkingStepsAPI } from '../services/APIs/InternalAPI/WorkingStepsAPI'
import { WorkingStepDto } from '../services/APIs/InternalAPI/internal-api.contracts'
import { APIFactory, BaseController } from './BaseController'
import { IWorkingStepsAPI } from './IWorkingStepsAPI'
import { BomItemController } from './Project/BomItemController'

export class WorkingStepsController extends BaseController<IWorkingStepsAPI> {
  static WorkingStepsOperations = {
    LOAD_DATA: 'WORKING_STEPS_LOAD_DATA',
    GetAvailableWorkingSteps: 'WORKING_STEPS_AVAILABLE_WORKING_STEPS',
    SAVE_DATA: 'WORKING_STEPS_SAVE_DATA',
    DELETE_DATA: 'WORKING_STEPS_DELETE_DATA',
    GET_FORM: 'WORKING_STEPS_GET_FORM',
    CHAGE_PRODUCTION_ORDER: 'WORKING_STEP_CHANGING_POSITION',
  } as const

  private projectId: string

  constructor(partyId?: string, apiFactory?: APIFactory<IWorkingStepsAPI>) {
    super(
      apiFactory
        ? apiFactory
        : (onRequestChange) =>
            new WorkingStepsAPI(
              partyId || store.getState().user?.organizationContext?.id,
              onRequestChange
            )
    )

    this.projectId = store.getState().project?.activeProject?.id
  }

  public async DeleteWorkingSteps(
    projectId: string,
    bomItemPointers: BomItemPointer[],
    workingStepsToRemove: WorkingStepDto[]
  ) {
    try {
      await this.api.DeleteWorkingSteps(
        projectId,
        bomItemPointers.map((x) => x.id),
        workingStepsToRemove.map((x) => x.primaryWorkingStep)
      )
    } catch (ex) {
      throw this.HandleError(ex)
    }
  }

  async DeleteAdditionalWorkingStepData(
    projectId: string,
    rowIds: string[],
    data: WorkingStepDto[]
  ) {
    try {
      await Promise.all(
        data.map((x) =>
          this.api.DeleteAdditionalWorkingStep(projectId, rowIds, x)
        )
      )
    } catch (err) {
      this.LogError(err)
      throw err
    }
  }

  public async SaveAdditionalWorkingSteps(
    projectId: string,
    bomItemPointers: BomItemPointer[],
    data: {
      workingStep: WorkingStepDto
      formData: Record<string, unknown>
      comment: string
    }[]
  ) {
    try {
      await Promise.all(
        data.map((x) =>
          this.api.SaveWorkingStepForm(
            projectId,
            bomItemPointers.map((x) => x.id),
            x.workingStep,
            x.formData,
            x.comment
          )
        )
      )
    } catch (err) {
      this.LogError(err)
      throw err
    }
  }

  async GetWorkingSteps(projectId: string, bomItemId: string) {
    try {
      return await this.api.GetWorkingSteps(projectId, bomItemId)
    } catch (err) {
      this.LogError(err)
      throw err
    }
  }

  async GetWorkingStepForm(
    projectId: string,
    bomItemPointer: BomItemPointer,
    workingStepType: WorkingStepType,
    isReloading?: boolean
  ): Promise<AdditionalWorkingStepFormConfiguration> {
    try {
      const resp = await this.api.GetWorkingStepForm(
        projectId,
        bomItemPointer.id,
        workingStepType,
        isReloading
      )

      return resp
        ? AdditionalWorkingStepFormModelBuilder().FromAPIResponse(resp, workingStepType)
        : null
    } catch (err) {
      this.LogError(err)
      throw err
    }
  }

  private SanitizeWorkingStepsFormData(
    workingStepsWithData: WorkingStepWithData[]
  ) {
    return workingStepsWithData.map((x) => {
      return {
        ...x,
        formData: Object.keys(x.formData || {}).reduce((acc, key) => {
          if (typeof x.formData[key] === 'string') {
            acc[key] = (x.formData[key] as string).trim()
          } else {
            acc[key] = x.formData[key]
          }

          return acc
        }, {}),
      }
    })
  }

  public async upInsertAndRemoveWorkingStepsWithData(
    bomItemPointers: BomItemPointer[],
    workingStepsToAdd: WorkingStepWithData[],
    workingStepsToRemove: WorkingStepDto[]
  ) {
    try {
      workingStepsToAdd = this.SanitizeWorkingStepsFormData(workingStepsToAdd)
      const shallRemovePurchasing =
        workingStepsToRemove?.findIndex(
          (x) => x.primaryWorkingStep === WorkingStepType.Purchasing
        ) > -1

      if (shallRemovePurchasing) {
        const bomItemController = new BomItemController()
        bomItemController.UnsetAsPurchasingItem(bomItemPointers)
      }

      // update it locally only if its not purchasing related
      if (!shallRemovePurchasing) {
        bomItemPointers.forEach((pointer) => {
          workingStepsToRemove.forEach((ws) => {
            store.dispatch(
              newProjectActions.removeWorkingStep({
                bomItemPointer: pointer,
                workingStepType: ws.primaryWorkingStep,
              })
            )
          })

          // workingStepsToAdd.forEach((ws) => {
          //   store.dispatch(
          //     newProjectActions.addWorkingStep({
          //       bomItemPointer: pointer,
          //       workingStep: ws,
          //     })
          //   )
          // })
        })
      }

      store.dispatch(newProjectActions.resetBomItemFinancials(bomItemPointers))

      // update it on the server
      if (
        workingStepsToRemove?.filter(
          (x) => x.primaryWorkingStep !== WorkingStepType.Purchasing
        )?.length > 0
      ) {
        await this.DeleteWorkingSteps(
          this.projectId,
          bomItemPointers,
          workingStepsToRemove.filter(
            (x) => x.primaryWorkingStep !== WorkingStepType.Purchasing
          )
        )
      }

      const workingStepsToSaveData: Array<{
        workingStep: WorkingStepDto
        formData: Record<string, unknown>
        comment: string
      }> = workingStepsToAdd?.map((x) => ({
        workingStep: x,
        formData: x.formData,
        comment: x.comment,
      }))

      if (workingStepsToSaveData?.length > 0) {
        await this.SaveAdditionalWorkingSteps(
          this.projectId,
          bomItemPointers,
          workingStepsToSaveData
        )
      }
    } catch (err) {
      store.dispatch(fetchProject({ projectId: this.projectId }))
      throw this.HandleError(err)
    }
  }

  public async handleSaveAndRemoveWorkingSteps(
    bomItemPointers: BomItemPointer[],
    additionalWorkingStepsData: {
      workingStep: WorkingStepDto
      formData: Record<string, unknown>
      isEmpty: boolean
      costDriveForm: AdditionalWorkingStepDto
      comment: string
    }[],
    workingStepsToRemove: WorkingStepDto[]
  ) {
    try {
      workingStepsToRemove?.forEach((ws) => {
        bomItemPointers.forEach((pointer) => {
          store.dispatch(
            newProjectActions.removeWorkingStep({
              bomItemPointer: pointer,
              workingStepType: ws.primaryWorkingStep,
            })
          )
        })
      })

      additionalWorkingStepsData?.forEach((data) => {
        bomItemPointers.forEach((rowId) => {
          store.dispatch(
            newProjectActions.addWorkingStep({
              bomItemPointer: rowId,
              workingStep: data.workingStep,
            })
          )
        })
      })

      store.dispatch(newProjectActions.refreshWorkingStepFilter())

      if (workingStepsToRemove?.length) {
        await this.DeleteWorkingSteps(
          this.projectId,
          bomItemPointers,
          workingStepsToRemove
        )
      }

      if (additionalWorkingStepsData?.length) {
        await this.SaveAdditionalWorkingSteps(
          this.projectId,
          bomItemPointers,
          additionalWorkingStepsData.map((x) => ({
            workingStep: x.workingStep,
            formData: x.formData,
            comment: x.comment,
          }))
        )
      }
    } catch (err) {
      this.LogError(err)
      store.dispatch(fetchProject({ projectId: this.projectId }))
      throw err
    }
  }

  public async SetWorkingStepManualDiscount(
    workingStepKey: string,
    workingStepType: WorkingStepType,
    args: ResourceWorkingStepManualDiscountArgs
  ) {
    try {
      store.dispatch(
        newProjectActions.setProjectStateProperties({
          priceSummaries: {
            [workingStepKey]: {
              discountRatio: args.discountRatio,
              discountValue: args.discountValue,
              totalSalesPrice: { value: null },
            },
          },
        } as ProjectState)
      )

      return await this.api.SetWorkingStepManualDiscount(
        this.projectId,
        workingStepType,
        args
      )
    } catch (err) {
      this.LogError(err)
      store.dispatch(fetchProject({ projectId: this.projectId }))
      throw err
    }
  }

  public async SetWorkingStepManualSurcharge(
    workingStepKey: string,
    workingStepType: WorkingStepType,
    args: ResourceWorkingStepManualSurchargeArgs
  ) {
    try {
      store.dispatch(
        newProjectActions.setProjectStateProperties({
          priceSummaries: {
            [workingStepKey]: {
              discountRatio: args.surchargeRatio,
              discountValue: args.surchargeValue,
              totalSalesPrice: { value: null },
            },
          },
        } as ProjectState)
      )

      return await this.api.SetWorkingStepManualSurcharge(
        this.projectId,
        workingStepType,
        args
      )
    } catch (err) {
      this.LogError(err)
      store.dispatch(fetchProject({ projectId: this.projectId }))

      throw err
    }
  }

  public async DeleteWorkingStepManualDiscount(
    workingStepType: WorkingStepType
  ) {
    try {
      return await this.api.DeleteWorkingStepManualDiscount(
        this.projectId,
        workingStepType
      )
    } catch (err) {
      this.LogError(err)
      throw err
    }
  }

  public async DeleteWorkingStepManualSurcharge(
    workingStepType: WorkingStepType
  ) {
    try {
      return await this.api.DeleteWorkingStepManualSurcharge(
        this.projectId,
        workingStepType
      )
    } catch (err) {
      this.LogError(err)
      throw err
    }
  }

  public async CopyWorkingStepsToOtherParts(
    baseBomItemPointer: BomItemPointer,
    sourceActivityId: string,
    workingStepType: WorkingStepType,
    bomItemsToApply?: BomItemPointer[]
  ) {
    try {
      return await this.api.CopyWorkingStepsToOtherParts(this.projectId, {
        sourcePartTypeId: baseBomItemPointer.id,
        sourceActivityId,
        workingStepType,
        destinationPartTypeIds: bomItemsToApply.map((x) => x.id),
      })
    } catch (err) {
      this.LogError(err)
      throw err
    }
  }

  /**
   * Changes the production order of a working step of one or more bom items
   * (assuming all bom items have the same working steps)
   * @param bomItemPointers items to be changed. assumes all working steps have the same workingsteps
   * @param workingStepType
   * @param oldProductionOrder
   * @param newProductionOrder
   * @returns
   */
  public async UpdateWorkingStepPosition(
    bomItemPointers: BomItemPointer[],
    workingStepType: WorkingStepType,
    oldProductionOrder: number,
    newProductionOrder: number
  ) {
    try {
      if (newProductionOrder === oldProductionOrder) {
        return null
      }

      const bomItem = bomItemSelectorFunc(bomItemPointers[0])(store.getState())

      // switch (bomItem.type) {
      //   case BomItemType.partType: {
      //     const workingStep = bomItem.workingSteps[oldProductionOrder - 1]
      //     if (workingStep.usesRawMaterial && newProductionOrder > 1) {
      //       // working steps that uses raw material must be first
      //       ShowException(
      //         'production order',
      //         'Working steps that uses raw material must be placed in the first position'
      //       )
      //       return false
      //     } else if (!workingStep.usesRawMaterial && newProductionOrder === 1) {
      //       ShowException(
      //         'production order',
      //         'Working steps that does not uses raw material must be placed after the working steps that uses raw material'
      //       )

      //       return false
      //     }
      //   }
      // }

      let workingStepToMoveAfter: WorkingStepType = undefined

      const steper = oldProductionOrder < newProductionOrder ? 0 : -1

      switch (bomItem.type) {
        case BomItemType.assemblyType:
        case BomItemType.assemblyInstance: {
          workingStepToMoveAfter =
            bomItem.assemblyActivities[newProductionOrder + steper]
              ?.primaryWorkingStep
          break
        }
        case BomItemType.materialHeader: {
          throw new Error("Can't change production order of material header")
        }
        case BomItemType.routingHeader: {
          throw new Error("Can't change production order of routing header")
        }
        default: {
          workingStepToMoveAfter =
            bomItem.activities[newProductionOrder + steper]?.primaryWorkingStep
        }
      }

      if (workingStepToMoveAfter === workingStepType) {
        console.error('working step to move after is the same as the current', {
          oldProductionOrder,
          newProductionOrder,
          workingStepToMoveAfter,
          workingStepType,
        })
      }

      bomItemPointers.forEach((bomItemPointer) => {
        store.dispatch(
          newProjectActions.setWorkingStepProductionOrder({
            bomItemPointer,
            workingStepTypeToMove: workingStepType,
            moveAfter: workingStepToMoveAfter,
          })
        )
      })

      const newWorkingStepTypeList =
        await this.api.UpdateWorkingStepProductionOrder(
          this.projectId,
          workingStepType,
          {
            bomItemIds: bomItemPointers.map((x) => x.id),
            moveAfter: workingStepToMoveAfter,
          }
        )

      // store.dispatch(fetchProject({ projectId: this.projectId }))

      return newWorkingStepTypeList
    } catch (err) {
      this.LogError(err)
      store.dispatch(fetchProject({ projectId: this.projectId }))
      throw err
    }
  }
}
