import { createAsyncThunk } from '@reduxjs/toolkit'
import { getNotificationGroupAction } from 'features/SignalR/components/ConnectionState'
import { noop, throttle } from 'lodash'
import { Notification } from 'model/Notification'
import { ShowException } from 'store/Application/appActions'
import store, { RootAppState } from 'store/configureStore'
import {
  fetchProject,
  getOperationsNotificationsGroupName,
  getProjectNotificationsGroupName,
} from './fetchProject'
import {
  subscribeToUpdates,
  unsubscribeFromUpdates,
} from './liveUpdatesActions'

// let handlerCounter = 0

const dispatchUpdate = (projectId: string) => {
  store.dispatch(
    fetchProject({
      projectId: projectId,
    })
  )
}

const throttledUpdateProject = throttle(dispatchUpdate, 1_500, {
  leading: true,
  trailing: true,
})

export const subscribeToProjectNotifications = createAsyncThunk<
  void,
  string,
  { state: RootAppState }
>('project/subscribeToUpdates', async (groupName, thunkAPI) => {
  thunkAPI.dispatch(
    subscribeToUpdates({
      groupName,
      clientMethodName: 'onProjectProgress',
      clientMethod: (notification: Notification) => {
        const projectId = thunkAPI.getState().project.activeProject?.id

        if (notification.shallUpdateUI) {
          throttledUpdateProject(projectId)
        }
      },
      onLeaveGroupCallback: (groupName) => {
        noop(groupName)
      },
    })
  )
})

export const subscribeToMultipleNotificationGroups = createAsyncThunk<
  void,
  string[],
  { state: RootAppState }
>(
  'project/subscribeToMultipleNotificationGroups',
  async (groupNames, thunkAPI) => {
    groupNames.forEach((groupName) => {
      thunkAPI.dispatch(getNotificationGroupAction(groupName))
    })
  }
)

export const unsubscribeFromActiveProjectUpdates = createAsyncThunk<
  void,
  { projectId: string; isBuyingPartyView: boolean },
  { state: RootAppState }
>(
  'project/unsubscribeFromUpdates',
  async ({ projectId, isBuyingPartyView }, thunkAPI) => {
    try {
      throttledUpdateProject.cancel()

      if (!projectId) {
        return
      }

      const projectUpdateGroupName = getProjectNotificationsGroupName(
        projectId,
        isBuyingPartyView
      )
      const projectOperationsGroupName = getOperationsNotificationsGroupName(
        projectId,
        isBuyingPartyView
      )

      await thunkAPI.dispatch(
        unsubscribeFromUpdates({
          groupName: projectUpdateGroupName,
          clientMethodName: 'onProjectProgress',
        })
      )
      await thunkAPI.dispatch(
        unsubscribeFromUpdates({
          groupName: projectOperationsGroupName,
          clientMethodName: 'onProjectOperationProgress',
        })
      )
    } catch (ex) {
      ShowException('project', ex)
      throw ex
    }
  }
)
