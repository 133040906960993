/* eslint-disable tss-unused-classes/unused-classes */
import { ModelController } from 'features/ModelViewer/cadexchanger/manager/ModelController'
import { FeatureDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { FeatureTreeNode } from './FeatureTreeNode'

type Props = {
  features: FeatureDto[]
  modelController: ModelController
  faceIdToFilter?: number
  variant?: 'small'
}

export const FeatureTree = (props: Props) => {
  return (
    <>
      {props.features.map((feature, index) => (
        <FeatureTreeNode
          key={index}
          feature={feature}
          index={index}
          modelController={props.modelController}
          faceIdToFilter={props.faceIdToFilter}
          variant={props.variant}
        />
      ))}
    </>
  )
}
