/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AsyncThunk,
  AsyncThunkOptions,
  AsyncThunkPayloadCreator,
  configureStore,
  Dispatch,
} from '@reduxjs/toolkit'
import GettingStartedReducer from 'features/GettingStarted/store/GettingStartedReducer'
import { ConnectionReducer } from 'features/SignalR/store/SignalRStore'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { reducer as toastrReducer } from 'react-redux-toastr'
import ProjectslistReducer from 'store/ProjectList/NewProjectListReducer'
import newProjectReducer from '../features/BillOfMaterials/store/projectReducer'
import * as App from './Application/appReducer'
import { CapabilitiesSettingsReducer } from './MachineActions/Reducer'
import * as Material from './MaterialSelector/MaterialStore'
import API from './Middlewares/API'
import * as Settings from './Settings/SettingsStore'
import TenantConfigurationReducer from './Tenant/TenantConfigurationReducer'
import { UIReducer } from './UI/UIReducer'
import newUserReducer from './User/newUserReducer'

const store = configureStore({
  reducer: {
    project: newProjectReducer,
    connection: ConnectionReducer,
    projectList: ProjectslistReducer,
    user: newUserReducer,
    toastr: toastrReducer,
    materials: Material.materialReducer,
    app: App.appReducer,
    settings: Settings.SettingsReducer,
    capabilitiesSettings: CapabilitiesSettingsReducer,
    ui: UIReducer,
    tenant: TenantConfigurationReducer,
    gettingStarted: GettingStartedReducer,
  },

  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false, // {
      // ignoredPaths: [
      //   'project.filters.byWorkingStep.filter.availableFilters',
      //   'newProjectReducer.filters.byWorkingStep.filter.availableFilters',
      // ],
      // ignoredActionPaths: [
      //   'payload.configuration.onSuccess',
      //   'payload.configuration.onError',
      //   'meta.arg',
      //   'ui.Modals.ModalProps',
      // ],
      // },
    }).concat(API)
  },
  devTools: {
    actionsDenylist: ['notification/add_notification', 'app/SetLoadingMessage'],
  },
})

export { store }
export default store

export type RootAppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootAppState> = useSelector

declare module '@reduxjs/toolkit' {
  /**
   * @see https://stackoverflow.com/questions/64793504/cannot-set-getstate-type-to-rootstate-in-createasyncthunk
   */
  type AsyncThunkConfig = {
    state?: unknown
    dispatch?: Dispatch
    extra?: unknown
    rejectValue?: unknown
    serializedErrorType?: unknown
  }

  function createAsyncThunk<
    Returned,
    ThunkArg = void,
    ThunkApiConfig extends AsyncThunkConfig = { state: RootAppState } // here is the magic line
  >(
    typePrefix: string,
    payloadCreator: AsyncThunkPayloadCreator<
      Returned,
      ThunkArg,
      ThunkApiConfig
    >,
    options?: AsyncThunkOptions<ThunkArg, ThunkApiConfig>
  ): AsyncThunk<Returned, ThunkArg, ThunkApiConfig>
}
