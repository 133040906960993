import { ProjectModel } from 'model/ProjectModel'
import {
  AssemblyDto,
  RowDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'
import { BomItemPointer } from '../../../../model/Project/BomItemPointer'

export const bomItemExportedToErpSelector =
  (bomItemPointer: BomItemPointer) => (state: RootAppState) => {
    let bomItem: ProjectModel | AssemblyDto | RowDto

    if (bomItemPointer.id === state.project?.activeProject?.id) {
      bomItem = state.project.activeProject
    } else {
      bomItem = bomItemSelector(state, bomItemPointer)
    }

    return {
      isExported: bomItem?.isExportedToErp,
      exportedResult: bomItem?.exportedToErpResult,
      exportedStatus: bomItem?.exportedToErpStatus,
    }
  }
