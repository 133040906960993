import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BomItemType } from 'model/Project/BoMItemRow'
import {
  IssueCode,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'

export const notAllowedWorkingStepsSelector =
  (bomItemPointer: BomItemPointer) =>
  (state: RootAppState): WorkingStepType[] => {
    if (!state.project.activeProject || !bomItemPointer.id) {
      return null
    }

    const bomItem = bomItemSelector(state, bomItemPointer)

    if (!bomItem) {
      return null
    }

    switch (bomItem?.type) {
      case BomItemType.partType:
      case BomItemType.partInstance:
        const workingStepNotAvailableIssue = bomItem.issues?.find(
          (x) => x.issueCode === IssueCode.WorkingStepNotAvailable
        )

        if (!workingStepNotAvailableIssue) {
          return null
        }

        return JSON.parse(workingStepNotAvailableIssue?.metadata || '{}')[
          'NotAllowedWorkingSteps'
        ]
      default:
        return null
    }
  }

export const allNotAllowedWorkingStepsSelector = (state: RootAppState) => {
  if (!state.project.activeProject) {
    return null
  }

  return state.project.groupedIssues?.WorkingStepNotAvailable
    ?.affectedWorkingSteps
}
