import { BaseController } from 'controllers/BaseController'
import { IPartyRelationsAPI } from './IPartyRelationsAPI'
import { OnRequestChangeCallback } from 'services/APIs/BaseAPI'
import { PartyRelationsAPI } from './PartyRelationsAPI'

export class PartyRelationsController extends BaseController<IPartyRelationsAPI> {
  constructor(
    private organizationId: string,
    apiFactory?: (
      onRequestChange: OnRequestChangeCallback
    ) => IPartyRelationsAPI
  ) {
    super(
      apiFactory
        ? apiFactory
        : (onRequestChanged) => new PartyRelationsAPI(onRequestChanged)
    )
  }

  public GetSellerParties() {
    return this.api.GetSellers(this.organizationId)
  }
}
