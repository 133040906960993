/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef } from 'react'
import { PaintContextMenuHandler } from '../manager/InputHandlers/PaintContextMenuHandler'
import { ModelController } from '../manager/ModelController'

type Point = {
  x: number
  y: number
}

export const PaintContextMenu = (props: { modelViewer: ModelController }) => {
  const contextMenuManager = useRef<PaintContextMenuHandler | undefined>(
    undefined
  )

  useEffect(() => {
    props.modelViewer.onSelectionColorChanged((color) => {
      contextMenuManager.current.color = color
    })
  })

  useEffect(() => {
    const initContextManager = () => {
      if (contextMenuManager.current) {
        props.modelViewer.RemoveInputHandler(contextMenuManager.current)
      }

      contextMenuManager.current = new PaintContextMenuHandler(
        props.modelViewer.getScene(),
        props.modelViewer.sceneStore
      )

      props.modelViewer.AddInputHandler(contextMenuManager.current)
    }

    props.modelViewer.onFileLoaded(initContextManager)
  }, [props.modelViewer])

  return <React.Fragment></React.Fragment>
}
