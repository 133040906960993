import { createAsyncThunk } from '@reduxjs/toolkit'
import { BomItemController } from 'controllers/Project/BomItemController'
import { ConfirmationDialogProps } from 'features/ConfirmationDialog/ConfirmationDialog'
import i18n from 'i18next'
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { ShowException } from 'store/Application/appActions'
import { UIActions } from 'store/UI/UIActions'
import { RootAppState } from 'store/configureStore'
import { getContext } from 'store/getContext'
import { newProjectActions } from '../projectReducer'
import { bomItemSelector } from '../selectors/bomItemSelector'
import { projectSelectors } from '../selectors/projectSelectors'
import { fetchProject } from './fetchProject'

export const saveBomItemIsPurchasingPart = createAsyncThunk<
  void,
  { bomItemPointer?: BomItemPointer },
  { state: RootAppState }
>('boM/saveBomItemIsPurchasingPart', async ({ bomItemPointer }, thunkAPI) => {
  // let setAsPurchasing = false

  const updateLocalState = (bomItemPointer: BomItemPointer) => {
    const bomItem = bomItemSelector(thunkAPI.getState(), bomItemPointer)

    if (
      bomItem?.type !== BomItemType.partType &&
      bomItem?.type !== BomItemType.assemblyInstance
    ) {
      throw Error(
        'only partTypes or sub assemblies can be set as purchasing part'
      )
    }

    thunkAPI.dispatch(
      newProjectActions.setBomItemProperties({
        bomItemPointer,
        properties: {
          isPurchasingBomItem: bomItem.isPurchasingBomItem ? false : true,
        },
      })
    )
    thunkAPI.dispatch(
      newProjectActions.resetBomItemFinancials([bomItemPointer])
    )

    // setAsPurchasing = !bomItem.isPurchasingBomItem
  }

  const updateServer = async (bomItemPointer: BomItemPointer) => {
    const { partyId: organizationId, projectId } = getContext(thunkAPI.getState)

    const controller = new BomItemController(organizationId, projectId)

    const bomItem = bomItemSelector(thunkAPI.getState(), bomItemPointer)

    switch (bomItem?.type) {
      case BomItemType.partType:
        try {
          updateLocalState(bomItemPointer)
          if (bomItem.isPurchasingBomItem) {
            return await controller.UnsetAsPurchasingItem([bomItemPointer])
          } else {
            return await controller.SetAsPurchasingItem([bomItemPointer])
          }
        } catch (err) {
          thunkAPI.dispatch(fetchProject({ projectId: projectId }))
          throw err
        }
      case BomItemType.assemblyInstance:
        thunkAPI.dispatch(
          UIActions.OpenModal('ConfirmationDialog', {
            title: i18n.t(
              'project:convert-to-purchasing-part',
              'purchasing part'
            ),
            onConfirm: async () => {
              try {
                updateLocalState(bomItemPointer)
                return await controller.ConvertSubAssemblyToPurchasingPart(
                  bomItemPointer
                )
              } catch (err) {
                thunkAPI.dispatch(fetchProject({ projectId: projectId }))
                ShowException(i18n.t('project:convert-to-purchasing-part'), err)
                throw err
              }
            },
            onCancel: () => {
              thunkAPI.dispatch(fetchProject({ projectId: projectId }))
            },
          } as ConfirmationDialogProps)
        )

        return Promise.resolve()
      default:
        throw Error(`${bomItem?.type} can't be set as purchasing part`)
    }
  }

  try {
    const bomItemPointers = bomItemPointer
      ? [bomItemPointer]
      : projectSelectors
          .selectedBomItemsPointersSelector(thunkAPI.getState())
          .map((x) => x)

    if (!bomItemPointers.length) {
      console.error('no bom item to set as purchasing parts')
      return
    }

    bomItemPointers.forEach(updateServer)

    if (bomItemPointers.length > 1) {
      thunkAPI.dispatch(newProjectActions.clearSelectedBomItems())
    }
  } catch (err) {
    ShowException('project', err)
    throw err
  }
})
