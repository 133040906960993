import { uniqBy } from 'lodash'
import {
  BoMItemActivityDto,
  KeywordDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'

export function GetAllBomItemActivitiesKeywords(
  activities: BoMItemActivityDto[]
): Record<string, KeywordDto[]> {
  return activities
    ?.map((x) => x?.keywords)
    ?.filter((x) => Boolean(x))
    ?.reduce((acc, val) => {
      Object.entries(val).forEach(([key, value]) => {
        acc[key] = (acc[key] || []).concat(value)

        acc[key] = uniqBy(acc[key], (x) => x.originalKeyword)
      })

      return acc
    }, {})
}
