import {
  AssemblyHeaderRow,
  AssemblyInstanceRow,
  BomItemType,
} from 'model/Project/BoMItemRow'
import {
  AssemblyHeaderPointer,
  AssemblyInstancePointer,
  PartInstancePointer,
  PartTypePointer,
} from 'model/Project/BomItemPointer'
import { BoMItemSatisfyActiveFilters } from 'utils/BoMItemSatisfyActiveFilters'
import { ProjectState } from '../../../../store/Project/ProjectTypes'
import { hasActiveFiltersSelector } from './hasActiveFiltersSelector'

/**
 * given a list of filtered pointers, updates the filtered part types of each header
 * @param state Project Redux State
 * @param filteredPointers the list of items that should be shown in the UI
 */
function updateFilteredPartTypesForAllHeaders(
  state: ProjectState,
  filteredPointers: PartTypePointer[]
) {
  state.assemblyHeadersIds.forEach((headerId) => {
    const header = state.assemblyHeaders[headerId]
    if (!header) {
      return
    }
    header.filteredPartTypePointers = filteredPointers.filter(
      (x) => header.partTypePointers.findIndex((y) => y.id === x.id) !== -1
    )
    state.assemblyHeaders[headerId] = header
  })

  state.materialHeadersIds.forEach((headerId) => {
    const header = state.materialHeaders[headerId]
    if (!header) {
      return
    }
    header.filteredPartTypePointers = filteredPointers.filter(
      (x) => header.partTypePointers.findIndex((y) => y.id === x.id) !== -1
    )
    state.materialHeaders[headerId] = header
  })

  state.routingHeadersIds.forEach((headerId) => {
    const header = state.routingHeaders[headerId]
    if (!header) {
      return
    }
    header.filteredPartTypePointers = filteredPointers.filter(
      (x) => header.partTypePointers.findIndex((y) => y.id === x.id) !== -1
    )
    state.routingHeaders[headerId] = header
  })
}

/**
 * given a list of filtered pointers, updates the filtered part instances of each header (mutating the state param)
 * @param state Project Redux State
 * @param filteredPointers the list of items that should be shown in the UI
 * @param filteredSubAssemblies the list of sub assemblies that should be shown in the UI
 */
function updateFilteredPartInstancesForAllHeaders(
  state: ProjectState,
  filteredPointers: PartInstancePointer[],
  filteredSubAssemblies: AssemblyInstancePointer[]
) {
  state.assemblyInstancesIds.forEach((headerId) => {
    const header = state.assemblyInstances[headerId]
    header.filteredPartInstancePointers = header.partInstancePointers.filter(
      (s) => filteredPointers.findIndex((x) => x.id === s.id) !== -1
    )

    header.filteredSubAssembliesPointers = header.subAssembliesPointers.filter(
      (s) => filteredSubAssemblies.findIndex((x) => x.id === s.id) !== -1
    )

    state.assemblyInstances[headerId] = header
  })

  state.assemblyHeadersIds.forEach((headerId) => {
    const header = state.assemblyHeaders[headerId]
    header.filteredSubAssembliesPointers = header.subAssembliesPointers.filter(
      (s) => filteredSubAssemblies.findIndex((x) => x.id === s.id) !== -1
    )

    header.filteredPartInstancePointers = header.partInstancePointers.filter(
      (s) => filteredPointers.findIndex((x) => x.id === s.id) !== -1
    )

    state.assemblyHeaders[headerId] = header
  })
}

/**
 * updates the visibility of the headers based on the active filters
 * @param state Project Redux State
 */
function updateHeadersVisibility(state: ProjectState) {
  const filteredAssemblyInstancesIds = state.assemblyInstancesIds.filter(
    (headerId) => {
      const header = state.assemblyInstances[headerId]

      return (
        header.filteredPartInstancePointers?.length > 0 ||
        BoMItemSatisfyActiveFilters(state, header)
      )
    }
  )

  filteredAssemblyInstancesIds.forEach((headerId) => {
    // adds the parent sub assembly to the list of filtered sub assemblies
    const header = state.assemblyInstances[headerId]
    let parentHeader: AssemblyHeaderRow | AssemblyInstanceRow =
      state.assemblyInstances[header.parentBomItemPointer.id]

    while (parentHeader) {
      filteredAssemblyInstancesIds.push(parentHeader.id)

      if (
        !parentHeader.parentBomItemPointer ||
        parentHeader.parentBomItemPointer.id === parentHeader.id
      ) {
        break
      }

      parentHeader =
        state.assemblyInstances[parentHeader.parentBomItemPointer.id] ||
        state.assemblyHeaders[parentHeader.parentBomItemPointer.id]
    }
  })

  state.assemblyInstancesIds.forEach((headerId) => {
    const header = state.assemblyInstances[headerId]
    header.filteredSubAssembliesPointers = header.subAssembliesPointers.filter(
      (s) => filteredAssemblyInstancesIds.includes(s.id)
    )
  })

  state.assemblyHeadersIds.forEach((headerId) => {
    const header = state.assemblyHeaders[headerId]
    header.filteredSubAssembliesPointers = header.subAssembliesPointers.filter(
      (s) => filteredAssemblyInstancesIds.includes(s.id)
    )
  })

  const filteredAssemblyHeaderIds = state.assemblyHeadersIds.filter(
    (headerId) => {
      const header = state.assemblyHeaders[headerId]
      return (
        header.filteredPartTypePointers?.length > 0 ||
        header.filteredPartInstancePointers?.length > 0 ||
        header.filteredSubAssembliesPointers?.length > 0 ||
        BoMItemSatisfyActiveFilters(state, header)
      )
    }
  )

  const filteredMaterialHeaderIds = state.materialHeadersIds.filter(
    (headerId) => {
      const header = state.materialHeaders[headerId]
      return header.filteredPartTypePointers?.length > 0
    }
  )

  const filteredRoutingHeaderIds = state.routingHeadersIds.filter(
    (headerId) => {
      const header = state.routingHeaders[headerId]
      return header.filteredPartTypePointers?.length > 0
    }
  )

  state.filteredAssemblyHeaderIds = filteredAssemblyHeaderIds
  state.filteredAssemblyInstanceIds = filteredAssemblyInstancesIds
  state.filteredMaterialHeaderIds = filteredMaterialHeaderIds
  state.filteredRoutingHeaderIds = filteredRoutingHeaderIds
}

/**
 * filter out parts of each header (mutanting the state parameter) based on the active filters
 * @param state
 * @returns the mutated state with the filtered parts pointers of each header updated
 * @remarks this function is called when the active filters change or when the project data changes
 */
export const updateHeadersFilteredParts = (state: ProjectState) => {
  if (!state.assemblyHeaders) {
    return state
  }

  if (!hasActiveFiltersSelector(false)({ project: state })) {
    // no active filters, all parts and assemblies are visible (just copy from bomitems Ids to filtered pointers)
    state.filteredAssemblyHeaderIds = state.assemblyHeadersIds
    state.filteredMaterialHeaderIds = state.materialHeadersIds
    state.filteredRoutingHeaderIds = state.routingHeadersIds
    state.filteredAssemblyInstanceIds = state.assemblyInstancesIds

    updateFilteredPartTypesForAllHeaders(
      state,
      state.partTypeIds.map((x) => ({ id: x, type: BomItemType.partType }))
    )
    updateFilteredPartInstancesForAllHeaders(
      state,
      state.partInstanceIds.map((x) => ({
        id: x,
        type: BomItemType.partInstance,
      })),
      state.assemblyInstancesIds.map((x) => ({
        id: x,
        type: BomItemType.assemblyInstance,
      }))
    )
  } else {
    const filteredPartTypesPointers: PartTypePointer[] = []
    const filteredPartInstancesPointers: PartInstancePointer[] = []
    const filteredAssemblyInstancesPointers: AssemblyInstancePointer[] = []
    const filteredAssembliesTypePointers: AssemblyHeaderPointer[] = []

    state.partTypeIds.forEach((partTypeId) => {
      const partType = state.partTypes[partTypeId]
      if (!partType) {
        return
      }

      if (BoMItemSatisfyActiveFilters(state, partType)) {
        filteredPartTypesPointers.push({
          id: partType.id,
          type: BomItemType.partType,
        })
      }
    })

    state.partInstanceIds.forEach((partInstanceId) => {
      const partInstance = state.partInstances[partInstanceId]
      if (!partInstance) {
        return
      }

      if (BoMItemSatisfyActiveFilters(state, partInstance)) {
        filteredPartInstancesPointers.push({
          id: partInstance.id,
          type: BomItemType.partInstance,
        })
      }
    })

    state.assemblyHeadersIds.forEach((assemblyHeaderId) => {
      const assemblyType = state.assemblyHeaders[assemblyHeaderId]
      if (!assemblyType) {
        return
      }

      if (BoMItemSatisfyActiveFilters(state, assemblyType)) {
        filteredAssembliesTypePointers.push({
          id: assemblyType.id,
          type: BomItemType.assemblyType,
        })
      }
    })

    state.assemblyInstancesIds.forEach((assemblyInstanceId) => {
      const assemblyInstance = state.assemblyInstances[assemblyInstanceId]
      if (!assemblyInstance) {
        return
      }

      if (BoMItemSatisfyActiveFilters(state, assemblyInstance)) {
        filteredAssemblyInstancesPointers.push({
          id: assemblyInstance.id,
          type: BomItemType.assemblyInstance,
        })
      }
    })

    updateFilteredPartTypesForAllHeaders(state, filteredPartTypesPointers)
    updateFilteredPartInstancesForAllHeaders(
      state,
      filteredPartInstancesPointers,
      filteredAssemblyInstancesPointers
    )
    updateHeadersVisibility(state)
  }

  return state
}
