import { RootAppState } from 'store/configureStore'

export const hasActiveFiltersSelector =
  /**
   * Return true if any of the filters are active
   * @param ignoreByPartTypeIdFilter the filter icon in the vertical menu should not show up if only the partTypeId filter is active, but the filter icon
   * in the header should. Pass true if you want to ignore the partTypeId filter.
   * @returns flag indicating if filters are active
   */
  (ignoreByPartTypeIdFilter?: boolean) => (state: Partial<RootAppState>) => {
    const hasActiveFilters =
      state.project.filters?.byIssues?.active ||
      state.project.filters?.byMaterialKeywords?.active ||
      state.project.filters?.byWorkingStep?.active ||
      (ignoreByPartTypeIdFilter
        ? false
        : state.project.filters?.byPartTypeId?.active) ||
      state.project.filters?.byResourceId?.active ||
      state.project.filters?.byArticleId?.active ||
      state.project.filters?.byManufacturability?.active ||
      state.project.filters?.byErpError?.active

    return hasActiveFilters
  }
