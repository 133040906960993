import { ModelController } from 'features/ModelViewer/cadexchanger/manager/ModelController'
import { FeatureSectionDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { FeatureTreeSection } from './FeatureTreeSection'

type Props = {
  featureSections: FeatureSectionDto[]
  modelController: ModelController
  onOpen: () => void
  isOpen?: boolean
  faceIdToFilter?: number
  variant: 'small'
}

export const FeatureTreeSections = (props: Props) => {
  const { featureSections, modelController, onOpen } = props

  return (
    <div>
      {featureSections.map((featureSection, index) => {
        if (props.faceIdToFilter) {
          if (
            !featureSection.faceIds.includes(props.faceIdToFilter) &&
            !featureSection.details?.find((x) =>
              x.faceIds.includes(props.faceIdToFilter)
            )
          ) {
            return null
          }
        }

        return (
          <FeatureTreeSection
            key={`${featureSection.label}-${index}`}
            featureSection={featureSection}
            modelController={modelController}
            onOpen={onOpen}
            isOpen={props.isOpen}
            faceIdToFilter={props.faceIdToFilter}
            variant={props.variant}
          />
        )
      })}
    </div>
  )
}
