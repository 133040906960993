import { createAsyncThunk } from '@reduxjs/toolkit'
import { BomItemController } from 'controllers/Project/BomItemController'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import {
  ResourceSummaryDto,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { RootAppState } from 'store/configureStore'
import { getContext } from 'store/getContext'
import { newProjectActions } from '../projectReducer'
import { fetchProject } from './fetchProject'

export const saveBomItemResource = createAsyncThunk<
  void,
  {
    bomItemPointers: BomItemPointer[]
    workingStepType: WorkingStepType
    resource: ResourceSummaryDto
    controllerInstance: BomItemController
  },
  { state: RootAppState }
>(
  'bom/save-resource',
  async (
    { bomItemPointers, workingStepType, resource, controllerInstance },
    thunkAPI
  ) => {
    const { projectId } = getContext(thunkAPI.getState)

    try {
      bomItemPointers.forEach((bomItemPointer) => {
        thunkAPI.dispatch(
          newProjectActions.setBomItemWorkingSteps({
            bomItemPointer: bomItemPointer,
            workingStepType: workingStepType,
            workingStep: {
              resource: {
                ...resource,
              },
            },
          })
        )
      })

      await controllerInstance.SetBomItemResource(
        bomItemPointers,
        workingStepType,
        resource.id
      )

      thunkAPI.dispatch(
        newProjectActions.setBomItemInSearch(bomItemPointers[0])
      )
    } catch (err) {
      thunkAPI.dispatch(fetchProject({ projectId: projectId }))
      ShowException('project', err)
    }
  }
)
