import { IssueDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ProjectState } from '../../../../store/Project/ProjectTypes'

export const refreshIssuesFilter = (state: ProjectState) => {
  const issues = state.partTypeIds.reduce((prev, current) => {
    if (state.partTypes[current]['issues']) {
      const issues: IssueDto[] = state.partTypes[current]['issues']

      prev.push(...issues)
    }

    return prev
  }, [] as IssueDto[])

  const newState: ProjectState = {
    ...state,
    filters: {
      ...state.filters,
      byIssues: {
        ...state.filters.byIssues,
        filter: {
          ...state.filters.byIssues.filter,
          availableFilters: issues,
        },
        active: state.filters?.byIssues.active && issues.length > 0,
      },
    },
  }

  return newState
}
