import { IWorkingStepsAPI } from 'controllers/IWorkingStepsAPI'
import {
  AdditionalWorkingStepDto,
  CopyWorkingStepArgs,
  ResourceWorkingStepManualDiscountArgs,
  ResourceWorkingStepManualSurchargeArgs,
  UpdateWorkingStepProductionOrderResponse,
  WorkingStepChangeProductionOrderArgs,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { WorkingStepsController } from '../../../controllers/WorkingStepsController'
import { OnRequestChangeCallback } from '../BaseAPI'
import { InternalAPI } from './InternalAPI'
import { WorkingStepDto, WorkingStepsDto } from './internal-api.contracts'

export class WorkingStepsAPI extends InternalAPI implements IWorkingStepsAPI {
  constructor(
    organizationId: string,
    private onRequestChange: OnRequestChangeCallback
  ) {
    super()
    this.baseAddress += `/api/parties/${organizationId}/projects`
  }

  async GetWorkingSteps(projectId: string, rowId: string) {
    return await this.GetAsync<WorkingStepsDto>({
      id: WorkingStepsController.WorkingStepsOperations
        .GetAvailableWorkingSteps,
      relativePath: `/${projectId}/items/${rowId}/workingsteps`,
      onRequestChange: this.onRequestChange,
    })
  }

  async SaveWorkingStepForm(
    projectId: string,
    rowIds: string[],
    workingStepDto: WorkingStepDto,
    formData: Record<string, unknown>,
    comment: string
  ) {
    return await this.PostAsync<void>({
      id: WorkingStepsController.WorkingStepsOperations.SAVE_DATA,
      relativePath: `/${projectId}/workingsteps`,
      data: {
        itemIds: rowIds,
        formData,
        workingStepDto,
        comment,
      },
      onRequestChange: this.onRequestChange,
    })
  }

  public async DeleteWorkingSteps(
    projectId: string,
    rowIds: string[],
    primaryWorkingStepsToDelete: WorkingStepType[]
  ) {
    return await this.PostAsync<void>({
      id: WorkingStepsController.WorkingStepsOperations.DELETE_DATA,
      relativePath: `/${projectId}/workingsteps/delete`,
      data: {
        itemIds: rowIds,
        primaryWorkingStepsToDelete,
      },
      onRequestChange: this.onRequestChange,
    })
  }

  async DeleteAdditionalWorkingStep(
    projectId: string,
    rowIds: string[],
    workingStepDto: WorkingStepDto
  ) {
    return await this.PostAsync<void>({
      relativePath: `/${projectId}/workingsteps/delete`,
      data: {
        itemIds: rowIds,
        primaryWorkingStep: workingStepDto.primaryWorkingStep,
      },
      onRequestChange: (req) => {
        this.onRequestChange({
          ...req,
          id:
            WorkingStepsController.WorkingStepsOperations.DELETE_DATA +
            `_${workingStepDto.key}`,
        })
      },
    })
  }

  async GetWorkingStepForm(
    projectId: string,
    bomItemId: string,
    workingStepType: WorkingStepType,
    isReloading?: boolean
  ) {
    return await this.GetAsync<AdditionalWorkingStepDto>({
      id:
        WorkingStepsController.WorkingStepsOperations.GET_FORM +
        `${isReloading ? '_reloading' : ''}`,
      relativePath: `/${projectId}/items/${bomItemId}/forms`,
      params: {
        primaryWorkingStep: workingStepType,
      },
      onRequestChange: this.onRequestChange,
    })
  }

  async SetWorkingStepManualDiscount(
    projectId: string,
    workingStepType: WorkingStepType,
    args: ResourceWorkingStepManualDiscountArgs
  ) {
    return await this.PutAsync<void>({
      id: `SetWorkingStepManualDiscount-${workingStepType}`,
      relativePath: `/${projectId}/workingsteps/${workingStepType}/discount`,
      data: args,
      onRequestChange: this.onRequestChange,
    })
  }

  async SetWorkingStepManualSurcharge(
    projectId: string,
    workingStepType: WorkingStepType,
    args: ResourceWorkingStepManualSurchargeArgs
  ) {
    return await this.PutAsync<void>({
      id: `SetWorkingStepManualSurcharge-${workingStepType}`,
      relativePath: `/${projectId}/workingsteps/${workingStepType}/surcharge`,
      data: args,
      onRequestChange: this.onRequestChange,
    })
  }

  async DeleteWorkingStepManualDiscount(
    projectId: string,
    workingStepType: WorkingStepType
  ) {
    return await this.DeleteAsync({
      id: `DeleteWorkingStepManualDiscount-${workingStepType}`,
      relativePath: `/${projectId}/workingsteps/${workingStepType}/discount`,
      onRequestChange: this.onRequestChange,
    })
  }

  async DeleteWorkingStepManualSurcharge(
    projectId: string,
    workingStepType: WorkingStepType
  ) {
    return await this.DeleteAsync({
      id: `DeleteWorkingStepManualSurcharge-${workingStepType}`,
      relativePath: `/${projectId}/workingsteps/${workingStepType}/surcharge`,
      onRequestChange: this.onRequestChange,
    })
  }

  async CopyWorkingStepsToOtherParts(
    projectId: string,
    args: CopyWorkingStepArgs
  ) {
    return await this.PostAsync<void>({
      id: `CopyWorkingStepsToOtherParts`,
      relativePath: `/${projectId}/workingsteps/CopyWorkingStep`,
      data: args,
      onRequestChange: this.onRequestChange,
    })
  }

  async UpdateWorkingStepProductionOrder(
    projectId: string,
    workingStepType: WorkingStepType,
    args: WorkingStepChangeProductionOrderArgs
  ) {
    return await this.PostAsync<
      Record<string, UpdateWorkingStepProductionOrderResponse>
    >({
      id: WorkingStepsController.WorkingStepsOperations.CHAGE_PRODUCTION_ORDER,
      relativePath: `/${projectId}/workingsteps/${workingStepType}/productionOrder`,
      data: args,
      onRequestChange: this.onRequestChange,
    })
  }
}
