import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import {
  AssemblyHeaderRow,
  AssemblyInstanceRow,
  BomItemType,
  MaterialHeaderRow,
  ProjectRow,
  RoutingHeaderRow,
} from 'model/Project/BoMItemRow'
import {
  AssemblyHeaderPointer,
  AssemblyInstancePointer,
  BomItemPointer,
  ProjectPointer,
} from 'model/Project/BomItemPointer'
import { ProjectViews } from 'store/UI/UITypes'
import { ProjectState } from '../../../../store/Project/ProjectTypes'
import { bomItemSelector } from '../selectors/bomItemSelector'

export const setBoMItemOpen: CaseReducer<
  ProjectState,
  PayloadAction<{
    bomItemPointer: BomItemPointer
    isOpen: boolean
    disableOpenParents?: boolean
  }>
> = (state, action) => {
  const bomItem = bomItemSelector(
    { project: state },
    action.payload.bomItemPointer
  )

  if (!bomItem) {
    return state
  }

  if (bomItem?.type === BomItemType.partType) {
    // open all headers where this row is a child
    // AssemblyHeader
    const parentAssembly = bomItemSelector(
      { project: state },
      bomItem.parentBomItemPointer
    ) as AssemblyHeaderRow | AssemblyInstanceRow

    parentAssembly && (parentAssembly.isOpen = action.payload.isOpen)

    // material header
    const materialHeader = bomItemSelector(
      { project: state },
      bomItem.materialHeaderPointer
    ) as MaterialHeaderRow

    materialHeader && (materialHeader.isOpen = action.payload.isOpen)

    // routing header
    const routingHeader = bomItemSelector(
      { project: state },
      bomItem.routingHeaderPointer
    ) as RoutingHeaderRow

    routingHeader && (routingHeader.isOpen = action.payload.isOpen)
  } else if (
    (bomItem?.type === BomItemType.assemblyType ||
      bomItem?.type === BomItemType.assemblyInstance) &&
    !action.payload.disableOpenParents
  ) {
    // open all parent headers
    let parentBomItemPointer = bomItem.parentBomItemPointer

    // open it self
    bomItem.isOpen = action.payload.isOpen

    // open parent assemblies if it exists
    while (
      Boolean(parentBomItemPointer?.id) &&
      (parentBomItemPointer.id !== state.activeProject?.id ||
        parentBomItemPointer.id !== action.payload.bomItemPointer.id)
    ) {
      const parent = bomItemSelector(
        { project: state },
        parentBomItemPointer
      ) as AssemblyHeaderRow | AssemblyInstanceRow | ProjectRow

      if (!parent) {
        break
      }

      parent.isOpen = action.payload.isOpen
      parentBomItemPointer = parent.parentBomItemPointer as
        | AssemblyHeaderPointer
        | AssemblyInstancePointer
        | ProjectPointer
    }
  } else if (
    bomItem?.type !== BomItemType.partInstance &&
    bomItem?.type !== BomItemType.project
  ) {
    bomItem.isOpen = action.payload.isOpen
  }

  return state
}

export const openOrCloseAllHeaders: CaseReducer<
  ProjectState,
  PayloadAction<boolean>
> = (state, action) => {
  switch (state.currentView) {
    case ProjectViews.DashboardView: {
      state.assemblyHeadersIds.forEach((headerId) =>
        setBoMItemOpen(state, {
          payload: {
            bomItemPointer: {
              id: headerId,
              type: BomItemType.assemblyType,
            },
            isOpen: action.payload,
          },
          type: 'setBomItemOpen',
        })
      )
      break
    }
    case ProjectViews.BoMBoLView:
      state.assemblyHeadersIds.forEach((headerId) =>
        setBoMItemOpen(state, {
          payload: {
            bomItemPointer: {
              id: headerId,
              type: BomItemType.assemblyType,
            },
            isOpen: action.payload,
          },
          type: 'setBomItemOpen',
        })
      )
      state.assemblyInstancesIds.forEach((headerId) =>
        setBoMItemOpen(state, {
          payload: {
            bomItemPointer: {
              id: headerId,
              type: BomItemType.assemblyInstance,
            },
            isOpen: action.payload,
          },
          type: 'setBomItemOpen',
        })
      )
      break
    case ProjectViews.MaterialsView:
      state.materialHeadersIds.forEach((headerId) =>
        setBoMItemOpen(state, {
          payload: {
            bomItemPointer: {
              id: headerId,
              type: BomItemType.materialHeader,
            },
            isOpen: action.payload,
          },
          type: 'setBomItemOpen',
        })
      )
      break
    case ProjectViews.RoutesView:
      state.routingHeadersIds.forEach((headerId) =>
        setBoMItemOpen(state, {
          payload: {
            bomItemPointer: {
              id: headerId,
              type: BomItemType.routingHeader,
            },
            isOpen: action.payload,
          },
          type: 'setBomItemOpen',
        })
      )
      break
    default:
      break
  }
}
