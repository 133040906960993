import { BomItemType } from 'model/Project/BoMItemRow'
import {
  IssueCode,
  IssueDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'
import { BomItemPointer } from '../../../../model/Project/BomItemPointer'

export const bomItemIssueCodeSelector =
  (bomItemPointer: BomItemPointer, issueCode: IssueCode) =>
  (state: RootAppState): IssueDto => {
    if (!state.project.activeProject) {
      return null
    }

    const bomItem = bomItemSelector(state, bomItemPointer)

    if (bomItem?.type !== BomItemType.partType) {
      return null
    }

    return bomItem.issues?.find((x) => x.issueCode === issueCode)
  }
