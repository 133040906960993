import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  PriceSummaryDto,
  QuantityDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { projectAPIBuilder } from './BoMAsyncActions'
import { fetchProject } from './fetchProject'
import { RootAppState } from 'store/configureStore'

const SaveCostDriverDiscountRatio = createAsyncThunk<
  Promise<unknown>,
  { newRatio: QuantityDto; priceSummary: PriceSummaryDto },
  { state: RootAppState }
>(
  'priceSummary/SaveCostDriverDiscountRatio',
  async ({ newRatio, priceSummary }, thunkAPI) => {
    const { api, projectId } = projectAPIBuilder(thunkAPI.getState)

    try {
      if (priceSummary?.isMaterial) {
        return await api.SetArticleDiscountRatio(
          projectId,
          priceSummary.materialId,
          {
            ...priceSummary.discountRatio,
            value: newRatio.value,
          }
        )
      } else if (priceSummary?.isWorkingStep) {
        return await api.SetResourceDiscountRatio(
          projectId,
          priceSummary.workingStep.resource.id,
          priceSummary.workingStep.primaryWorkingStep,
          priceSummary.workingStep.secondaryWorkingStep,
          {
            ...priceSummary.discountRatio,
            value: newRatio.value,
          }
        )
      }
    } catch (err) {
      ShowException('project', err)
      thunkAPI.dispatch(fetchProject({ projectId: projectId }))
      throw err
    }
  }
)

const SaveCostDriverSurchargeRatio = createAsyncThunk<
  void,
  { newRatio: QuantityDto; priceSummary: PriceSummaryDto },
  { state: RootAppState }
>(
  'priceSummary/SaveCostDriverSurchargeRatio',
  async ({ newRatio, priceSummary }, thunkAPI) => {
    const { api, projectId } = projectAPIBuilder(thunkAPI.getState)

    try {
      if (priceSummary?.isMaterial) {
        return await api.SetArticleSurchargeRatio(
          projectId,
          priceSummary.materialId,
          {
            ...priceSummary.surchargeRatio,
            value: newRatio.value,
          }
        )
      } else if (priceSummary?.isWorkingStep) {
        return await api.SetResourceSurchargeRatio(
          projectId,
          priceSummary.workingStep.resource.id,
          priceSummary.workingStep.primaryWorkingStep,
          priceSummary.workingStep.secondaryWorkingStep,
          {
            ...priceSummary.surchargeRatio,
            value: newRatio.value,
          }
        )
      }
    } catch (err) {
      ShowException('project', err)
      thunkAPI.dispatch(fetchProject({ projectId: projectId }))
      throw err
    }
  }
)

const ResetCostDriverDiscountToDefault = createAsyncThunk<
  void,
  { priceSummary: PriceSummaryDto },
  { state: RootAppState }
>(
  'priceSummary/resetCostDriverDiscountToDefault',
  async ({ priceSummary }, thunkAPI) => {
    const { api, projectId } = projectAPIBuilder(thunkAPI.getState)

    try {
      if (priceSummary?.isMaterial) {
        return await api.DeleteArticleDiscount(
          projectId,
          priceSummary.materialId
        )
      } else if (priceSummary?.isWorkingStep) {
        return await api.DeleteResourceDiscount(
          projectId,
          priceSummary.workingStep.resource.id,
          priceSummary.workingStep
        )
      }
    } catch (err) {
      ShowException('project', err)
      thunkAPI.dispatch(fetchProject({ projectId: projectId }))
      throw err
    }
  }
)

const ResetCostDriverSurchargeToDefault = createAsyncThunk<
  void,
  { priceSummary: PriceSummaryDto },
  { state: RootAppState }
>(
  'priceSummary/ResetCostDriverSurchargeToDefault',
  async ({ priceSummary }: { priceSummary: PriceSummaryDto }, thunkAPI) => {
    const { api, projectId } = projectAPIBuilder(thunkAPI.getState)

    try {
      if (priceSummary?.isMaterial) {
        return await api.DeleteArticleSurcharge(
          projectId,
          priceSummary.materialId
        )
      } else if (priceSummary?.isWorkingStep) {
        return await api.DeleteResourceSurcharge(
          projectId,
          priceSummary.workingStep.resource.id,
          priceSummary.workingStep
        )
      }
    } catch (err) {
      ShowException('project', err)
      thunkAPI.dispatch(fetchProject({ projectId: projectId }))
      throw err
    }
  }
)

export const CostDriversActions = {
  SaveCostDriverDiscountRatio,
  ResetCostDriverDiscountToDefault,
  SaveCostDriverSurchargeRatio,
  ResetCostDriverSurchargeToDefault,
}
