import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'

export function headerRowsCounter(
  state: RootAppState,
  bomItemPointer: BomItemPointer
): { filtered: number; total: number } {
  if (!state.project.activeProject) {
    return {
      filtered: 0,
      total: 0,
    }
  }

  const bomItem = bomItemSelector(state, bomItemPointer)

  if (!bomItem) {
    console.warn('headerRowsCounter: bomItem not found', bomItemPointer)
    return { filtered: 0, total: 0 }
  }

  switch (bomItem?.type) {
    case BomItemType.partType:
    case BomItemType.partInstance:
    case BomItemType.project:
      break
    case BomItemType.routingHeader:
      return {
        filtered: bomItem.filteredPartTypePointers.length,
        total: bomItem.bomItems.length,
      }
    default:
      return {
        filtered:
          bomItem?.type === BomItemType.assemblyInstance
            ? bomItem.filteredPartInstancePointers.length
            : bomItem.filteredPartTypePointers.length,
        total:
          bomItem?.type === BomItemType.assemblyInstance
            ? bomItem.partInstancePointers.length
            : bomItem.partTypeIds.length,
      }
  }

  return { filtered: 0, total: 0 }
}
