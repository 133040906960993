import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProjectStateAsString } from 'controllers/Project/ProjectStateMachine'
import { ProjectsAPI } from 'controllers/Projects/ProjectsAPI'
import { ConfirmationDialogProps } from 'features/ConfirmationDialog/ConfirmationDialog'
import i18n from 'i18next'
import { ErrorMessage, ShowException } from 'store/Application/appActions'
import { UIActions } from 'store/UI/UIActions'
import { RootAppState } from 'store/configureStore'
import { GetPartyId } from 'store/storeUtils'
import { fetchProjectSummaries } from './fetchProjectSummaries'

/**
 * do not call this directly, use deleteProjects.
 * this one is just to keep track of async operation status
 */
export const deleteProjectsAction = createAsyncThunk<
  void,
  { partyId: string; projectIds: string[] },
  { state: RootAppState }
>(
  'projectList/deleteProjectAction',
  async (payload: { partyId: string; projectIds: string[] }, thunkAPI) => {
    try {
      const projectsAPI = new ProjectsAPI(payload.partyId)
      await projectsAPI.DeleteProjects(payload.projectIds)
    } catch (err) {
      ShowException('project delete', err)
      thunkAPI.dispatch(fetchProjectSummaries({ subscribeToUpdates: false }))
    }
  }
)

export const deleteProjects = createAsyncThunk<
  void,
  Array<{
    projectId: string
    projectStatus: ProjectStateAsString
    confirmationDone?: boolean
  }>,
  { state: RootAppState }
>('projectList/deleteProject', async (deleteRequests, thunkAPI) => {
  const partyId = GetPartyId()

  const orderedProjects = deleteRequests.filter(
    (x) =>
      x.projectStatus === ProjectStateAsString[ProjectStateAsString.ORDERED]
  )

  if (orderedProjects.length === deleteRequests.length) {
    ErrorMessage(
      i18n.t('common:delete-project'),
      i18n.t(
        'errors:cannot-delete-project-in-current-state',
        'projects with ordered status cannot be deleted.'
      )
    )

    return
  }

  const deleteProjectAction = () =>
    thunkAPI.dispatch(
      deleteProjectsAction({
        partyId: partyId,
        projectIds: deleteRequests
          .filter(
            (x) =>
              x.projectStatus !==
              ProjectStateAsString[ProjectStateAsString.ORDERED]
          )
          .map((x) => x.projectId),
      })
    )

  if (deleteRequests.length === 1 && deleteRequests[0].confirmationDone) {
    deleteProjectAction()
    return
  }

  if (orderedProjects.length > 0) {
    thunkAPI.dispatch(
      UIActions.OpenModal('ConfirmationDialog', {
        title: i18n.t('common:delete-project', 'delete projects'),
        message: i18n.t(
          'common:delete-projects-with-ordered-confirmation',
          'projects with ordered status cannot be deleted. Do you want to proceed deleting other {{count}} project?',
          {
            count: deleteRequests.length - orderedProjects.length,
          }
        ),
        onConfirm: deleteProjectAction,
      } as ConfirmationDialogProps)
    )

    return
  }

  thunkAPI.dispatch(
    UIActions.OpenModal('ConfirmationDialog', {
      title: i18n.t(
        'common:delete-projects-count',
        'delete {{count}} project',
        {
          count: deleteRequests.length - orderedProjects.length,
        }
      ),
      onConfirm: deleteProjectAction,
    })
  )
})
