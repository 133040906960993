import LogRocket from 'logrocket'
import {
  BomItemType,
  Header,
  PartInstanceRow,
  PartTypeRow,
} from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { RootAppState } from 'store/configureStore'

const headerTypes = [
  BomItemType.assemblyType,
  BomItemType.assemblyInstance,
  BomItemType.materialHeader,
  BomItemType.routingHeader,
]

export function getPreviousAndNextPointers(
  state: RootAppState,
  headerType: BomItemType,
  bomItemPointer: BomItemPointer
): {
  previousBomItemPointer: BomItemPointer
  nextBomItemPointer: BomItemPointer
} {
  let previous: BomItemPointer
  let next: BomItemPointer

  try {
    if (!state.project.activeProject) {
      return {
        nextBomItemPointer: undefined,
        previousBomItemPointer: undefined,
      }
    }

    if (headerTypes.includes(bomItemPointer.type)) {
      // it's a header, next is the first object, previous is the last object of parent
      let filteredHeaderIdKey: string = undefined
      let headerKey: string = undefined
      switch (headerType) {
        case BomItemType.assemblyType:
          filteredHeaderIdKey = 'filteredAssemblyHeaderIds'
          headerKey = 'assemblyHeaders'
          break
        case BomItemType.assemblyInstance:
          filteredHeaderIdKey = 'filteredAssemblyInstanceIds'
          headerKey = 'assemblyInstances'
          break
        case BomItemType.materialHeader:
          filteredHeaderIdKey = 'filteredMaterialHeaderIds'
          headerKey = 'materialHeaders'
          break
        case BomItemType.routingHeader:
          filteredHeaderIdKey = 'filteredRoutingHeaderIds'
          headerKey = 'routingHeaders'
          break
      }

      const headerIndex = state.project[filteredHeaderIdKey]?.findIndex(
        (x) => x === bomItemPointer.id
      )

      previous = getItemOfAdjacentHeader(
        'previous',
        headerIndex,
        filteredHeaderIdKey,
        headerKey
      )

      next =
        state.project?.[headerKey]?.[bomItemPointer.id]?.[
          headerType === BomItemType.assemblyInstance
            ? 'filteredPartInstancePointers'
            : 'filteredPartTypePointers'
        ]?.[0]
    } else {
      const part =
        headerType === BomItemType.assemblyInstance
          ? (state.project.partInstances?.[
              bomItemPointer.id
            ] as PartInstanceRow)
          : (state.project.partTypes?.[bomItemPointer.id] as PartTypeRow)

      if (!part) {
        console.warn(
          'getPreviousAndNextPointers: partType not found',
          bomItemPointer
        )
        return {
          nextBomItemPointer: undefined,
          previousBomItemPointer: undefined,
        }
      }

      let partHeader: Header = undefined
      let partFilteredHeaderIdsKey: string = undefined
      let partHeaderKey: string = undefined

      switch (headerType) {
        case BomItemType.assemblyType:
          partHeader =
            state.project.assemblyHeaders[part.parentBomItemPointer.id]
          partFilteredHeaderIdsKey = 'filteredAssemblyHeaderIds'
          partHeaderKey = 'assemblyHeaders'
          break
        case BomItemType.assemblyInstance: {
          partHeader =
            state.project.assemblyInstances[part.parentBomItemPointer.id]
          partFilteredHeaderIdsKey = 'filteredAssemblyInstanceIds'
          partHeaderKey = 'assemblyInstances'
          break
        }
        case BomItemType.materialHeader: {
          if (part.type === BomItemType.partType) {
            partHeader =
              state.project.materialHeaders[part.materialHeaderPointer.id]
            partFilteredHeaderIdsKey = 'filteredMaterialHeaderIds'
            partHeaderKey = 'materialHeaders'
          }

          break
        }
        case BomItemType.routingHeader: {
          if (part.type === BomItemType.partType) {
            partHeader =
              state.project.routingHeaders[part.routingHeaderPointer.id]
            partFilteredHeaderIdsKey = 'filteredRoutingHeaderIds'
            partHeaderKey = 'routingHeaders'
          }

          break
        }
      }

      const filteredObjects =
        headerType === BomItemType.assemblyInstance
          ? partHeader.filteredPartInstancePointers
          : partHeader.filteredPartTypePointers

      const partIndex = filteredObjects.findIndex(
        (x) => x.id === bomItemPointer.id
      )

      const partHeaderIndex: number = state.project[
        partFilteredHeaderIdsKey
      ].findIndex((x: string) => x === partHeader.id)

      if (partIndex === filteredObjects.length - 1) {
        // last partType, previous is the previous partType, next is the next materialHeader
        previous = filteredObjects[partIndex - 1]

        if (!previous) {
          // just 1 partType in the header. previous is the header only for
          // assemblyType and assemblyInstance
          switch (headerType) {
            case BomItemType.assemblyType:
            case BomItemType.assemblyInstance:
              previous = part.parentBomItemPointer
              break
            default:
              previous = getItemOfAdjacentHeader(
                'previous',
                partHeaderIndex,
                partFilteredHeaderIdsKey,
                partHeaderKey
              )
          }
        }

        next = getItemOfAdjacentHeader(
          'next',
          partHeaderIndex,
          partFilteredHeaderIdsKey,
          partHeaderKey
        )
      } else if (partIndex === 0) {
        // first partType, previous is the last row of previous header or the project
        if (!previous) {
          // just 1 partType in the header. previous is the header only for
          // assemblyType and assemblyInstance
          switch (headerType) {
            case BomItemType.assemblyType:
            case BomItemType.assemblyInstance:
              previous = part.parentBomItemPointer
              break
            default:
              previous = getItemOfAdjacentHeader(
                'previous',
                partHeaderIndex,
                partFilteredHeaderIdsKey,
                partHeaderKey
              )
          }
        }

        next = filteredObjects[partIndex + 1]
      } else {
        // middle partType, previous is the previous partType, next is the next partType
        previous = filteredObjects[partIndex - 1]
        next = filteredObjects[partIndex + 1]
      }
    }

    function getItemOfAdjacentHeader(
      previousOrNext: 'previous' | 'next',
      headerIndex: number,
      filteredHeaderIdKey: string,
      headerKey: string,
      allowHeaders: BomItemType[] = [
        BomItemType.assemblyType,
        BomItemType.assemblyInstance,
      ]
    ): BomItemPointer {
      if (!state.project.activeProject) {
        return undefined
      }

      if (headerIndex <= 0 && previousOrNext === 'previous') {
        return {
          id: state.project.activeProject.id,
          type: BomItemType.project,
        }
      }

      const otherHeaderId =
        state.project[filteredHeaderIdKey][
          headerIndex + (previousOrNext === 'next' ? 1 : -1)
        ]

      if (!otherHeaderId) {
        return {
          id: state.project.activeProject.id,
          type: BomItemType.project,
        }
      }

      const otherHeader: Header = state.project[headerKey][otherHeaderId]

      if (
        previousOrNext === 'next' &&
        allowHeaders.includes(otherHeader.type)
      ) {
        return {
          id: otherHeader.id,
          type: otherHeader.type,
        }
      } else {
        // get latest partType of previous header
        if (otherHeader.type === BomItemType.assemblyInstance) {
          return otherHeader.filteredPartInstancePointers[
            previousOrNext === 'previous'
              ? otherHeader.filteredPartInstancePointers.length - 1
              : 0
          ]
        } else {
          return otherHeader.filteredPartTypePointers[
            previousOrNext === 'previous'
              ? otherHeader.filteredPartTypePointers.length - 1
              : 0
          ]
        }
      }
    }

    return {
      previousBomItemPointer: previous,
      nextBomItemPointer: next,
    }
  } catch (err) {
    console.error(err)
    LogRocket.captureException(err as Error)

    return {
      previousBomItemPointer: state.project.partTypeIds[0]
        ? { id: state.project.partTypeIds[0], type: BomItemType.partType }
        : undefined,
      nextBomItemPointer: state.project.partTypeIds[1]
        ? { id: state.project.partTypeIds[1], type: BomItemType.partType }
        : undefined,
    }
  }
}
