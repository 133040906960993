import { AppState } from '@auth0/auth0-react'
import { useAppRunningContext } from 'features/EmbeddedRh24/useAppRunningContext'
import { isEqual } from 'lodash'
import { AppModule } from 'services/APIs/InternalAPI/internal-api.contracts'
import { useAppSelector } from 'store/configureStore'

export function useAppModule(moduleToCheck: AppModule) {
  const enabledModules = useAppSelector(
    (state) => state.user.organizationContext?.modules,
    isEqual
  )

  const { withinIframe } = useAppRunningContext()

  if (!moduleToCheck || withinIframe) {
    return true
  }

  if (!enabledModules?.length) {
    return true
  }

  if (enabledModules.includes(AppModule.Enterprise)) {
    return true // all features are enabled
  }

  return enabledModules.includes(moduleToCheck)
}

export function currentAppModuleSelector(state: AppState): AppModule {
  const enabledModules = state.user.organizationContext?.enabledModules

  if (!enabledModules?.length) {
    return AppModule.Enterprise // by default all features are enabled
  }

  if (enabledModules.includes(AppModule.Enterprise)) {
    return AppModule.Enterprise
  }

  return AppModule.BoMManager
}

export function useCurrentAppModule(): AppModule {
  const appModule = useAppSelector(currentAppModuleSelector, isEqual)

  return appModule
}
