import { BomItemType } from 'model/Project/BoMItemRow'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'
import { BomItemPointer } from '../../../../model/Project/BomItemPointer'

export const bomItemAttachmentSelector =
  (bomItemPointer: BomItemPointer) => (state: RootAppState) => {
    if (bomItemPointer.id === state.project?.activeProject?.id) {
      return state.project.activeProject.attachments
    }

    const bomItem = bomItemSelector(state, bomItemPointer)

    if (!bomItem) return null

    if (
      bomItem?.type === BomItemType.materialHeader ||
      bomItem?.type === BomItemType.routingHeader
    ) {
      throw Error('material header does not have attachments')
    }

    return bomItem?.attachments
  }

export const bomItemAttachmentCounterSelector =
  (bomItemPointer: BomItemPointer) => (state: RootAppState) => {
    return bomItemAttachmentSelector(bomItemPointer)(state)?.length
  }
