import { OnRequestChangeCallback } from 'services/APIs/BaseAPI'
import {
  IPartyRelationsAPI,
  PartyRelationsOperations,
} from './IPartyRelationsAPI'
import { InternalAPI } from 'services/APIs/InternalAPI/InternalAPI'
import { SellingPartyDto } from 'services/APIs/InternalAPI/internal-api.contracts'

export class PartyRelationsAPI
  extends InternalAPI
  implements IPartyRelationsAPI
{
  constructor(private onRequestChange: OnRequestChangeCallback) {
    super()
    this.baseAddress += `/api/parties`
  }

  public GetSellers(organizationId: string) {
    return this.GetAsync<SellingPartyDto[]>({
      id: PartyRelationsOperations.GetSellers,
      relativePath: `/${organizationId}/sellers`,
      onRequestChange: this.onRequestChange,
    })
  }
}
