import { createAsyncThunk } from '@reduxjs/toolkit'
import { WorkingStepsController } from 'controllers/WorkingStepsController'
import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { WorkingStepDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from '../selectors/bomItemSelector'

export const applyWorkingStepToAllParentFilteredRows = createAsyncThunk<
  Promise<void>,
  {
    bomItemPointer: BomItemPointer
    activityId: string
    workingStep: WorkingStepDto
  },
  { state: RootAppState }
>(
  'bom/applyWorkingStepToAllParentFilteredRows',
  async ({ bomItemPointer, activityId, workingStep }, thunkAPI) => {
    const bomItem = bomItemSelector(thunkAPI.getState(), bomItemPointer)

    try {
      if (bomItem?.type === BomItemType.partType) {
        const parentBomItem = bomItemSelector(
          thunkAPI.getState(),
          bomItem.parentBomItemPointer
        )
        if (
          parentBomItem.type === BomItemType.assemblyType ||
          parentBomItem.type === BomItemType.materialHeader
        ) {
          const workingStepsController = new WorkingStepsController()

          return await workingStepsController.CopyWorkingStepsToOtherParts(
            bomItemPointer,
            activityId,
            workingStep.primaryWorkingStep,
            parentBomItem.filteredPartTypePointers.filter(
              (x) => x.id !== bomItemPointer.id
            )
          )
          // return await workingStepsController.SaveAdditionalWorkingSteps(
          //   projectId,
          //   parentBomItem.filteredPartTypePointers,
          //   [{ workingStep: workingStep, formData: undefined }]
          // )
        }
      } else {
        throw 'ApplyWorkingStepToAll needs a bomItem of type Row'
      }
    } catch (ex) {
      ShowException('project', ex)
      throw ex
    }
  }
)
