import { BaseController } from 'controllers/BaseController'
import { fetchProject } from 'features/BillOfMaterials/store/asyncActions/fetchProject'
import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import { projectSelectors } from 'features/BillOfMaterials/store/selectors/projectSelectors'
import { AssemblySimplifyModel } from 'model/AssemblySimplifyModel'
import { BomItemType, PartTypeRow } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { batch } from 'react-redux'
import {
  FeaturesDto,
  GeometricTolerance,
  IssueCode,
  KeywordDto,
  LinearAndAngleTolerance,
  ManuallySetAdditionalCostsArgs,
  ManuallySetProductionTimeArgs,
  MoneyDto,
  NestingDirection,
  PrecisionsDto,
  PriceRowDto,
  PriceRowsDto,
  PriceScope,
  ProjectSimplifyDto,
  ResourceSummaryDto,
  RowDto,
  SetKeywordsOrArticleToPartsArgs,
  SetMaterialCostPriceArgs,
  SetPrecisionsArgs,
  SurfaceRoughness,
  UpdateBomItemActivityCommentRequest,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { TelemetryService } from 'services/Telemetry'
import { store } from 'store/configureStore'
import { GetContext } from 'store/storeUtils'
import { BomItemAPI } from './BomItemAPI'

export enum FeatureType {
  Engraving = 1,
  CircularHole = 2,
  Bending = 3,
  Rolling = 4,
  CounterSinking = 5,
}

export interface IBomItemAPI {
  // GETS
  GetBomItem: (itemId: string) => Promise<RowDto>
  GetFilteredFeatures: (
    itemId: string,
    featureType: FeatureType
  ) => Promise<string>
  GetSelectableResources: (
    itemId: string,
    workingStepType: WorkingStepType
  ) => Promise<ResourceSummaryDto[]>

  Get3DCdxfbFile: (itemId: string, fileName: string) => Promise<ArrayBuffer>
  Get2DCdxfbFile: (itemId: string, fileName: string) => Promise<ArrayBuffer>

  GetPartTypeGroups: () => Promise<ProjectSimplifyDto>

  downloadBomItemFiles: (itemId: string) => Promise<boolean>
  downloadAssemblyFiles: (itemId: string) => Promise<boolean>
  downloadProjectFiles: (itemId: string) => Promise<boolean>
  // POSTS and PUTS
  SetNestingDirection: (
    itemId: string,
    direction: NestingDirection
  ) => Promise<void>
  SetBomItemName: (itemId: string, name: string) => Promise<void>
  SetBomQuantity: (itemId: string, quantity: number) => Promise<void>
  // AddKeywordsToParts: (itemIds: string[], materialSummary: MaterialSummaryDto) => Promise<void>
  SetKeywordsOrArticleToParts: (
    itemIds: string[],
    args: SetKeywordsOrArticleToPartsArgs
  ) => Promise<void>
  SetAsPurchasingItem: (bomItemId: string) => Promise<void>
  UnsetAsPurchasingItem: (bomItemId: string) => Promise<void>
  SetPartTypeGroups: (assemblyId: string, partIds: string[]) => Promise<void>

  SetSelectedResource: (
    itemIds: string[],
    workingStepType: WorkingStepType,
    resourceId: string
  ) => Promise<void>
  ManualSetProductionTime: (
    itemId: string,
    args: ManuallySetProductionTimeArgs
  ) => Promise<void>
  DeleteManualSetProductionTime: (
    itemId: string,
    primaryWorkingStep: WorkingStepType,
    secondaryWorkingStep: WorkingStepType
  ) => Promise<void>
  ManualSetAdditionalCosts: (
    itemId: string,
    args: ManuallySetAdditionalCostsArgs
  ) => Promise<void>
  DeleteManualSetAdditionalCosts: (
    itemId: string,
    primaryWorkingStep: WorkingStepType,
    secondaryWorkingStep: WorkingStepType
  ) => Promise<void>
  SetMaterialCostPrice: (
    itemId: string,
    req: SetMaterialCostPriceArgs
  ) => Promise<void>
  DeleteMaterialCostPrice: (itemId: string) => Promise<void>
  CheckManufacturability: (
    itemId: string,
    primaryWorkingStep: WorkingStepType,
    secondaryWorkingStep: WorkingStepType
  ) => Promise<void>
  SwitchManufacturabilityFlag: (itemId: string) => Promise<void>
  updateComment: (itemId: string, comment: string) => Promise<void>
  solveIssues: (itemId: string[], issueCodes: IssueCode[]) => Promise<void>
  convertSubAssemblyToPurchasingPart: (itemId: string) => Promise<void>
  setBomItemPrecisions: (SetPrecisionsArgs: SetPrecisionsArgs) => Promise<void>
  GetItemPriceDetails: (itemId: string) => Promise<PriceRowsDto>
  GetItemFeaturesDetails: (itemId: string) => Promise<FeaturesDto>
  SetActivityArticles: (
    boMItemId: string,
    boMItemActivityId: string,
    articles: string[]
  ) => Promise<void>
  updateActivityComment: (
    itemId: string,
    req: UpdateBomItemActivityCommentRequest
  ) => Promise<void>
}

export class BomItemController extends BaseController<IBomItemAPI> {
  constructor(organizationId?: string, private projectId?: string) {
    super((onRequestChanged) => {
      let _organizationId = organizationId
      let _projectId = projectId

      if (!_organizationId || !_projectId) {
        const { partyId, projectId } = GetContext()
        _organizationId = partyId
        _projectId = projectId
      }

      return new BomItemAPI(_organizationId, _projectId, onRequestChanged)
    })
  }

  public async SetNestingDirection(
    bomItemPointer: BomItemPointer,
    nestingDirection: NestingDirection
  ) {
    try {
      await this.api.SetNestingDirection(bomItemPointer.id, nestingDirection)
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async GetFilteredFeatures(itemId: string, featureType: FeatureType) {
    try {
      return await this.api.GetFilteredFeatures(itemId, featureType)
    } catch (err) {
      TelemetryService.getInstance().logError(err)

      throw err
    }
  }

  public async GetItemFeaturesDetails(bomItemPointer: BomItemPointer) {
    try {
      if (!GetContext().projectId) {
        return null
      }

      let typeId = bomItemPointer.id

      if (bomItemPointer.type === BomItemType.partInstance) {
        // needs to send id of the parttype
        const partTypePointer = projectSelectors.partInstancePartTypeSelector(
          bomItemPointer
        )(store.getState())

        if (!partTypePointer) {
          console.error(
            'unable to get part type pointer from part instance pointer',
            bomItemPointer
          )
        }

        typeId = partTypePointer.id
      }

      const resp = await this.api.GetItemFeaturesDetails(typeId)

      return resp
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async GetItemPriceDetails(bomItemPointer: BomItemPointer) {
    try {
      if (!GetContext().projectId) {
        return null
      }

      const resp = await this.api.GetItemPriceDetails(bomItemPointer.id)

      if (!resp?.priceRows) {
        return null
      }

      resp.priceRows = resp.priceRows.map((x) => {
        return {
          ...x,
          productionTime: x.productionTime
            ? {
                ...x.productionTime,
                value: x.productionTime.value
                  ? parseFloat(x.productionTime?.value.toFixed(2))
                  : null,
              }
            : null,
        }
      })

      return resp
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async GetBomItem(bomItemId: string) {
    try {
      return await this.api.GetBomItem(bomItemId)
    } catch (ex) {
      throw this.HandleError(ex)
    }
  }

  public async SwitchPurchasingPart(row: PartTypeRow) {
    return row.isPurchasingBomItem
      ? this.UnsetAsPurchasingItem([row])
      : this.SetAsPurchasingItem([row])
  }

  public async SetAsPurchasingItem(bomItemPointers: BomItemPointer[]) {
    try {
      return await Promise.all(
        bomItemPointers.map((x) => this.api.SetAsPurchasingItem(x.id))
      )
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async UnsetAsPurchasingItem(bomItemPointers: BomItemPointer[]) {
    try {
      return await Promise.all(
        bomItemPointers.map((x) => this.api.UnsetAsPurchasingItem(x.id))
      )
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async SetKeywordsOrArticleToParts({
    bomItemPointers: bomItemPointer,
    keywords,
    articleId,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    articleDescription,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    materialHeaderPointer,
  }: {
    bomItemPointers: BomItemPointer[]
    keywords?: KeywordDto[]
    articleId?: string
    articleDescription?: string
    materialHeaderPointer?: BomItemPointer
  }) {
    try {
      //TODO: localy update the activity with the new keywords

      // bomItemPointer.forEach((pointer) => {
      //   store.dispatch(
      //     newProjectActions.setBomItemProperties({
      //       bomItemPointer: pointer,
      //       properties: {
      //         materialSummary: {
      //           tokens: _.groupBy(keywords, (x) => x.category),
      //           id: articleId,
      //           description: articleDescription,
      //         },
      //         financial: {
      //           salesPricePerItem: null,
      //           salesPriceOfItems: null,
      //         },
      //       },
      //     })
      //   )
      // })

      // if (materialHeaderPointer) {
      //   store.dispatch(
      //     newProjectActions.setBomItemProperties({
      //       bomItemPointer: materialHeaderPointer,
      //       properties: {
      //         materialSummary: {
      //           tokens: _.groupBy(keywords, (x) => x.category),
      //           id: articleId,
      //           description: articleDescription,
      //         },
      //       },
      //     })
      //   )
      // }

      return await this.api.SetKeywordsOrArticleToParts(
        bomItemPointer.map((x) => x.id),
        {
          keywords: keywords?.map((x) => x.originalKeyword),
          articleId: articleId,
        }
      )
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async SetBomItemQuantity(
    bomItemPointer: BomItemPointer,
    quantity: number
  ) {
    try {
      return await this.api.SetBomQuantity(bomItemPointer.id, quantity)
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async SetBomItemName(bomItemPointer: BomItemPointer, name: string) {
    try {
      return await this.api.SetBomItemName(bomItemPointer.id, name)
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async GetPartTypeGroups() {
    try {
      const response = await this.api.GetPartTypeGroups()

      if (!response?.assemblySimplifyDtos) {
        return null
      }

      const viewModels = response.assemblySimplifyDtos.map(
        (x) =>
          ({ ...x, partGroups: [], hashGroups: {} } as AssemblySimplifyModel)
      )

      const responseWithoutDup = viewModels.reduce((prev, curr, index, acc) => {
        const accIndex = acc.findIndex((x) => x.assemblyId === curr.assemblyId)

        if (accIndex > -1) {
          // acc.splice(accIndex, 1, { ...acc[accIndex], partIds: [...acc[accIndex].partIds, ...curr.partIds] })
          acc.splice(accIndex, 1, {
            ...acc[accIndex],
            hashGroups: {
              ...acc[accIndex].hashGroups,
              [index]: { partTypeIds: curr.partIds },
            },
          })
        } else {
          acc.push({
            ...curr,
            hashGroups: {
              [index]: {
                partTypeIds: curr.partIds,
              },
            },
          })
        }

        return acc
      }, [])

      return responseWithoutDup.filter((x) => Boolean(x.hashGroups))
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async SetPartTypeGroups(
    assemblyId: string,
    partTypeIds: string[],
    dispatch
  ) {
    try {
      if (partTypeIds.length <= 1) return

      batch(() => {
        partTypeIds.forEach(
          (rowId, index) =>
            index > 0 &&
            dispatch(
              newProjectActions.setBomItemProperties({
                bomItemPointer: {
                  id: rowId,
                  type: BomItemType.partType,
                },
                properties: {
                  operationPending: true,
                },
              })
            )
        )
      })

      return await this.api.SetPartTypeGroups(assemblyId, partTypeIds)
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async GetBomItemSelectableResources(
    bomItemPointer: BomItemPointer,
    workingStepType: WorkingStepType
  ) {
    try {
      return await this.api.GetSelectableResources(
        bomItemPointer.id,
        workingStepType
      )
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async SetBomItemResource(
    bomItemPointers: BomItemPointer[],
    workingStepType: WorkingStepType,
    resourceId: string
  ) {
    try {
      return await this.api.SetSelectedResource(
        bomItemPointers.map((x) => x.id),
        workingStepType,
        resourceId
      )
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async ManualSetProductionTime(
    bomItemPointer: BomItemPointer,
    req: ManuallySetProductionTimeArgs
  ) {
    try {
      if (req.priceScope.toString() === 'perUnit') {
        req.priceScope = PriceScope.Unit
      }

      return await this.api.ManualSetProductionTime(bomItemPointer.id, req)
    } catch (ex) {
      throw this.HandleError(ex)
    }
  }

  public async DeleteManualSetProductionTime(
    bomItemPointer: BomItemPointer,
    priceRow: PriceRowDto
  ) {
    try {
      return await this.api.DeleteManualSetProductionTime(
        bomItemPointer.id,
        priceRow.workingStep.primaryWorkingStep,
        priceRow.workingStep.secondaryWorkingStep
      )
    } catch (ex) {
      throw this.HandleError(ex)
    }
  }

  public async ManualSetAdditionalCosts(
    bomItemPointer: BomItemPointer,
    req: ManuallySetAdditionalCostsArgs
  ) {
    try {
      return await this.api.ManualSetAdditionalCosts(bomItemPointer.id, req)
    } catch (ex) {
      throw this.HandleError(ex)
    }
  }

  public async DeleteManualSetAdditionalCosts(
    bomItemPointer: BomItemPointer,
    priceRow: PriceRowDto
  ) {
    try {
      return await this.api.DeleteManualSetAdditionalCosts(
        bomItemPointer.id,
        priceRow.workingStep.primaryWorkingStep,
        priceRow.workingStep.secondaryWorkingStep
      )
    } catch (ex) {
      throw this.HandleError(ex)
    }
  }

  public async SetMaterialCostPrice(
    bomItemPointer: BomItemPointer,
    costPrice: MoneyDto,
    priceScope: PriceScope
  ) {
    try {
      return await this.api.SetMaterialCostPrice(bomItemPointer.id, {
        costPrice: {
          ...costPrice,
          value: costPrice.value || 0,
        },
        priceScope,
      })
    } catch (ex) {
      throw this.HandleError(ex)
    }
  }

  public async DeleteMaterialCostPrice(itemId) {
    try {
      return await this.api.DeleteMaterialCostPrice(itemId)
    } catch (ex) {
      throw this.HandleError(ex)
    }
  }

  public async CheckManufacturability(
    rowId: string,
    primaryWorkingStep: WorkingStepType
  ) {
    try {
      return await this.api.CheckManufacturability(
        rowId,
        primaryWorkingStep,
        primaryWorkingStep
      )
    } catch (ex) {
      throw this.HandleError(ex)
    }
  }

  public async SwitchManufacturabilityFlag(bomItemPointer: BomItemPointer) {
    try {
      return await this.api.SwitchManufacturabilityFlag(bomItemPointer.id)
    } catch (ex) {
      throw this.HandleError(ex)
    }
  }

  public async updateComment(
    bomItemPointer: BomItemPointer,
    commentText: string
  ) {
    try {
      return await this.api.updateComment(bomItemPointer.id, commentText)
    } catch (ex) {
      throw this.HandleError(ex)
    }
  }

  public async updateActivityComment(
    bomItemPointer: BomItemPointer,
    req: UpdateBomItemActivityCommentRequest
  ) {
    try {
      return await this.api.updateActivityComment(bomItemPointer.id, req)
    } catch (ex) {
      throw this.HandleError(ex)
    }
  }

  public async downloadBomItemFiles(bomItemPointer: BomItemPointer) {
    try {
      switch (bomItemPointer.type) {
        case BomItemType.assemblyType: {
          return await this.api.downloadAssemblyFiles(bomItemPointer.id)
        }
        case BomItemType.partType: {
          return await this.api.downloadBomItemFiles(bomItemPointer.id)
        }
        case BomItemType.project: {
          return await this.api.downloadProjectFiles(bomItemPointer.id)
        }
        default:
          return null
      }
    } catch (ex) {
      throw this.HandleError(ex)
    }
  }

  public async SolveIssue(
    bomItemPointers: BomItemPointer[],
    issueCode: IssueCode
  ) {
    try {
      return await this.api.solveIssues(
        bomItemPointers.map((x) => x.id),
        [issueCode]
      )
    } catch (ex) {
      throw this.HandleError(ex)
    }
  }

  public async ConvertSubAssemblyToPurchasingPart(
    bomItemPointer: BomItemPointer
  ) {
    try {
      return await this.api.convertSubAssemblyToPurchasingPart(
        bomItemPointer.id
      )
    } catch (ex) {
      throw this.HandleError(ex)
    }
  }

  public async SetBomItemPrecisions(
    bomItemPointers: BomItemPointer[],
    precisions: PrecisionsDto
  ) {
    try {
      bomItemPointers.forEach((pointer) => {
        store.dispatch(
          newProjectActions.setBomItemProperties({
            bomItemPointer: pointer,
            properties: {
              precisions: precisions,
            },
          })
        )
      })

      return await this.api.setBomItemPrecisions({
        bomItemIds: bomItemPointers.map((x) => x.id),
        geometricTolerance: precisions.geometricTolerance
          .value as GeometricTolerance,
        surfaceRoughness: precisions.surfaceRoughness.value as SurfaceRoughness,
        linearAndAngleTolerance: precisions.linearAndAngleTolerance
          .value as LinearAndAngleTolerance,
      })
    } catch (err) {
      store.dispatch(
        fetchProject({
          projectId: GetContext().projectId,
        })
      )
      throw this.HandleError(err)
    }
  }

  public async Get2dViewerFile(
    bomItemPointer: BomItemPointer,
    fileName: string
  ) {
    try {
      let typeId = bomItemPointer.id

      if (bomItemPointer.type === BomItemType.partInstance) {
        // needs to send id of the parttype
        const partTypePointer = projectSelectors.partInstancePartTypeSelector(
          bomItemPointer
        )(store.getState())

        if (!partTypePointer) {
          console.error(
            'unable to get part type pointer from part instance pointer',
            bomItemPointer
          )
        }

        typeId = partTypePointer.id
      }

      return await this.api.Get2DCdxfbFile(typeId, fileName)
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async Get3dViewerFile(
    bomItemPointer: BomItemPointer,
    fileName: string
  ) {
    try {
      let typeId = bomItemPointer.id

      if (bomItemPointer.type === BomItemType.partInstance) {
        // needs to send id of the parttype
        const partTypePointer = projectSelectors.partInstancePartTypeSelector(
          bomItemPointer
        )(store.getState())

        if (!partTypePointer) {
          console.error(
            'unable to get part type pointer from part instance pointer',
            bomItemPointer
          )
        }

        typeId = partTypePointer.id
      }

      return await this.api.Get3DCdxfbFile(typeId, fileName)
    } catch (err) {
      throw this.HandleError(err)
    }
  }
}
