import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { ModelController } from 'features/ModelViewer/cadexchanger/manager/ModelController'
import React from 'react'
import { FeatureDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { FeatureTreeItem } from './FeatureTreeItem'
import { FeatureTreeSections } from './FeatureTreeSections'

type Props = {
  feature: FeatureDto
  index: number
  modelController: ModelController
  faceIdToFilter?: number
  variant?: 'small'
}

export const FeatureTreeNode = (props: Props) => {
  const [expanded, setExpanded] = React.useState(
    props.faceIdToFilter ? true : false
  )
  return (
    <Accordion
      key={props.index}
      expanded={expanded}
      onChange={(e, expanded) => setExpanded(expanded)}
      variant="outlined"
    >
      {!props.faceIdToFilter && (
        <AccordionSummary>{props.feature.label}</AccordionSummary>
      )}
      <AccordionDetails>
        {props.feature.details?.map((detail, index) => {
          if (
            props.faceIdToFilter &&
            !detail.faceIds.includes(props.faceIdToFilter)
          ) {
            return null
          }

          return (
            <FeatureTreeItem
              key={`${detail.label}-${index}`}
              featureDetail={detail}
              modelController={props.modelController}
              onOpen={() => setExpanded(true)}
              isOpen={expanded}
              variant={props.variant}
            />
          )
        })}
        {props.feature.sections && (
          <FeatureTreeSections
            featureSections={props.feature.sections}
            modelController={props.modelController}
            onOpen={() => {
              setExpanded(true)
            }}
            isOpen={expanded}
            faceIdToFilter={props.faceIdToFilter}
            variant={props.variant}
          />
        )}
      </AccordionDetails>
    </Accordion>
  )
}
