import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { ProjectState } from 'store/Project/ProjectTypes'

type UpdateOperationsFlagsArgs = {
  isWerk24IntegrationWorkloadRunning?: boolean
  shallUpdateOperationsSummary?: boolean
  shallUpdateOperationsLogs?: boolean
  isExportingToErp?: boolean
  isNesting?: boolean
  isManufacturabilityCheckRunning?: boolean
  isImnooIntegrationWorkloadRunning?: boolean
}

export const updateOperationsFlags: CaseReducer<
  ProjectState,
  PayloadAction<UpdateOperationsFlagsArgs>
> = (state, action) => {
  state.projectOperations.isNesting =
    action.payload.isNesting ?? state.projectOperations.isNesting

  state.projectOperations.isExportingToErp =
    action.payload.isExportingToErp ?? state.projectOperations.isExportingToErp

  state.projectOperations.isManufacturabilityCheckRunning =
    action.payload.isManufacturabilityCheckRunning ??
    state.projectOperations.isManufacturabilityCheckRunning

  state.projectOperations.isImnooIntegrationWorkloadRunning =
    action.payload.isImnooIntegrationWorkloadRunning ??
    state.projectOperations.isImnooIntegrationWorkloadRunning

  state.projectOperations.isWerk24IntegrationWorkloadRunning =
    action.payload.isWerk24IntegrationWorkloadRunning ??
    state.projectOperations.isWerk24IntegrationWorkloadRunning

  state.projectOperations.shallUpdateSummary =
    action.payload.shallUpdateOperationsSummary ??
    state.projectOperations.shallUpdateSummary ??
    false

  state.projectOperations.shallUpdateOperations =
    action.payload.shallUpdateOperationsLogs ??
    state.projectOperations.shallUpdateOperations ??
    false

  return state
}
