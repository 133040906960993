import { ClickAwayListener, Paper } from '@mui/material'
import { FeatureTree } from 'components/ProjectPage/BillOfMaterials/RowDetails/FeatureTree'
import { useCallback, useEffect, useRef, useState } from 'react'
import { FeatureDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ModelContextMenuHandler } from '../manager/InputHandlers/ModelContextMenuHandler'
import { ModelController } from '../manager/ModelController'

type ModelContextMenuProps = {
  modelViewer: ModelController
}

type Position = {
  x: number
  y: number
}

type ShapeData = {
  partName: string
  shapeId: string
  shapeName: string
  shapeType: string
}

export const ModelContextMenu = (props: ModelContextMenuProps) => {
  const contextMenuManager = useRef<ModelContextMenuHandler | undefined>(
    undefined
  )

  const [position, setPosition] = useState<Position | undefined>(undefined)

  const [shapeData, setShapeData] = useState<ShapeData | undefined>(undefined)

  const [faceFeatures, setFaceFeatures] = useState<FeatureDto[]>([])

  const menuRef = useRef<HTMLDivElement>(null)

  const onSelectionChanged = useCallback(
    (
      name: string,
      positionClicked: { x: number; y: number },
      shapeId: string,
      shapeName: string,
      shapeType: string
    ) => {
      const faceFeatures = props.modelViewer.modelFeatures?.filter(
        (f) =>
          f.details.find((x) => x.faceIds.includes(parseInt(shapeId))) ||
          f.sections.find((x) => x.faceIds.includes(parseInt(shapeId))) ||
          f.sections.find((x) =>
            x.details.find((y) => y.faceIds.includes(parseInt(shapeId)))
          )
      )

      setFaceFeatures(faceFeatures)

      // to fit in the screen
      setPosition(positionClicked)
      setShapeData({ partName: name, shapeId, shapeName, shapeType })
    },
    [props.modelViewer.modelFeatures]
  )

  useEffect(() => {
    const initContextManager = () => {
      if (contextMenuManager.current) {
        props.modelViewer.RemoveInputHandler(contextMenuManager.current)
      }
      contextMenuManager.current = new ModelContextMenuHandler(
        props.modelViewer.getScene(),
        props.modelViewer.sceneStore,
        onSelectionChanged
      )

      props.modelViewer.AddInputHandler(contextMenuManager.current)
    }

    props.modelViewer.onFileLoaded(initContextManager)

    return () => {
      props.modelViewer.RemoveInputHandler(contextMenuManager.current)
    }
  }, [onSelectionChanged, props.modelViewer])

  if (!faceFeatures?.length) return null

  // ensure it fits in the screen
  const newPosition = { x: 0, y: 0 }
  const parentElement = menuRef.current?.parentElement
  if (parentElement?.clientWidth - position?.x < 350) {
    newPosition.x = position.x - 250
  } else {
    newPosition.x = position?.x || 0
  }

  if (parentElement?.clientHeight - position?.y < 200 * faceFeatures?.length) {
    newPosition.y =
      position.y - menuRef?.current?.getBoundingClientRect().height
  } else {
    newPosition.y = position?.y || 0
  }

  return (
    <ClickAwayListener onClickAway={() => setPosition(undefined)}>
      <Paper
        sx={{
          position: 'absolute',
          top: newPosition?.y || -1000,
          left: newPosition?.x || -1000,
          zIndex: 10000,
          minWidth: '250px',
          maxHeight: '500px',
          padding: (theme) => theme.spacing(1),
          overflow: 'auto',
        }}
        ref={menuRef}
      >
        <FeatureTree
          features={faceFeatures}
          modelController={props.modelViewer}
          faceIdToFilter={
            shapeData?.shapeId ? parseInt(shapeData.shapeId) : undefined
          }
          variant="small"
        />
      </Paper>
    </ClickAwayListener>
  )
}
