import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BomItemType } from 'model/Project/BoMItemRow'
import { RowDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'

export const bomItemGeometryTypeSelector =
  (bomItemPointer: BomItemPointer) => (state: RootAppState) => {
    const bomItem = bomItemSelector(state, bomItemPointer)

    switch (bomItemPointer.type) {
      case BomItemType.partType:
      case BomItemType.partInstance:
        return (bomItem as RowDto).geometryType
      default:
        return undefined
    }
  }
