import { TelemetryService } from 'services/Telemetry'
import { BaseAPI, OnRequestChangeCallback } from '../services/APIs/BaseAPI'

type APIError = {
  message: string
  validation?: Record<string, string>
  status: number
}

export type APIFactory<APIDefinition> = (
  onRequestChange: OnRequestChangeCallback
) => APIDefinition

export abstract class BaseController<APIDefinition> {
  public loading: { [operationKey: string]: boolean } = {}
  private error: {
    [operationKey: string]: APIError
  } = {}
  public validation: { [validationKey: string]: string } = {}

  public progress: { [validationKey: string]: number } = {}

  protected currentOperation: string
  protected api: APIDefinition

  public CancelRequests() {
    ;(this.api as unknown as BaseAPI).CancelRequests()
  }

  constructor(apiFactory: APIFactory<APIDefinition>) {
    this.api = apiFactory && apiFactory(this.onRequestChange)
  }

  private subscribers: ((status: {
    loading: { [operationKey: string]: boolean }
    error: {
      [operationKey: string]: APIError
    }
    validation: { [validationKey: string]: string }
    progress: { [validationKey: string]: number }
  }) => void)[] = []

  public GetIsLoading(operationKey: string) {
    return this.loading[operationKey]
  }

  public subscribeToUpdates(
    callback: (status: { loading; error; validation; progress }) => void
  ) {
    this.subscribers.push(callback)
  }

  protected notify() {
    this.subscribers.forEach((s) =>
      s({
        loading: this.loading,
        error: this.error,
        validation: this.validation,
        progress: this.progress,
      })
    )
  }

  protected sendNotification(
    operationId: string,
    isLoading: boolean,
    err?: {
      message: string
      validation?: Record<string, string>
      status: number
    },
    validation?: { [validationKey: string]: string }
  ) {
    this.onRequestChange({
      id: operationId,
      isLoading,
      error: err,
      validation: validation ?? this.validation,
    })
  }
  protected notifyError(
    err: {
      message: string
      validation?: Record<string, string>
      status: number
    },
    operationId?: string
  ) {
    this.onRequestChange({
      id: operationId || this.currentOperation,
      isLoading: false,
      error: err,
    })
  }

  protected onRequestChange: OnRequestChangeCallback = (req) => {
    // console.log('onRequestChange', req)

    this.loading = {
      ...this.loading,
      [req.id]: req.isLoading,
    }
    // this.loading[req.id] = req.isLoading
    this.error[req.id] = req.error

    if (req.error && req.id) {
      delete this.loading[req.id]
    }

    this.currentOperation = req.id

    this.progress[req.id] = req.progress ?? 0

    this.notify()
  }

  protected LogError(err) {
    TelemetryService.getInstance().logError(err)
  }

  protected HandleError(err) {
    if (err.message === 'user cancelled') {
      return null
    }

    if (err.message === 'Request aborted') {
      return null
    }

    this.LogError(err)

    return err
  }

  public setLoading(key: string, loading: boolean) {
    if (!loading) {
      delete this.loading[key]
    } else {
      this.loading[key] = true
    }

    this.notify()
  }
}
