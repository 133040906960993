export enum ProjectStatus {
  CANCELLED = -1,
  DEFINING = 0,
  REQUESTED = 1,
  QUOTING = 2,
  QUOTED = 3,
  NEGOTIATING = 4,
  ORDERED = 5,
  PRODUCING = 6,
  PRODUCED = 7,
  PACKAGING = 8,
  PACKAGED = 9,
  DELIVERING = 10,
  DELIVERED = 11,
}

export enum ProjectStateAsString {
  CANCELLED = 'CANCELLED',
  DEFINING = 'DEFINING',
  REQUESTED = 'REQUESTED',
  QUOTING = 'QUOTING',
  QUOTED = 'QUOTED',
  NEGOTIATING = 'NEGOTIATING',
  ORDERED = 'ORDERED',
  PRODUCING = 'PRODUCING',
  PRODUCED = 'PRODUCED',
  PACKAGING = 'PACKAGING',
  PACKAGED = 'PACKAGED',
  DELIVERING = 'DELIVERING',
  DELIVERED = 'DELIVERED',
}

export enum ProjectStatusLabelBeforeCurrentState {
  CANCELLED = 'cancelled',
  DEFINING = 'defining',
  REQUESTED = 'request',
  QUOTING = 'quote',
  QUOTED = 'quote',
  NEGOTIATING = 'negotiating',
  ORDERED = 'order',
  PRODUCING = 'producing',
  PRODUCED = 'produced',
  PACKAGING = 'packaging',
  PACKAGED = 'packaged',
  DELIVERING = 'delivering',
  DELIVERED = 'delivered',
}

export enum ProjectStatusLabelDuringCurrentState {
  CANCELLED = 'cancelled',
  DEFINING = 'defining',
  REQUESTED = 'requested',
  QUOTING = 'quoted',
  QUOTED = 'quoted',
  NEGOTIATING = 'negotiating',
  ORDERED = 'ordered',
  PRODUCING = 'producing',
  PRODUCED = 'produced',
  PACKAGING = 'packaging',
  PACKAGED = 'packaged',
  DELIVERING = 'delivering',
  DELIVERED = 'delivered',
}

export enum ProjectStatusLabelAfterCurrentState {
  CANCELLED = 'cancelled',
  DEFINING = 'defined',
  REQUESTED = 'requested',
  QUOTING = 'quoted',
  QUOTED = 'quoted',
  NEGOTIATING = 'negotiated',
  ORDERED = 'ordered',
  PRODUCING = 'produced',
  PRODUCED = 'produced',
  PACKAGING = 'packaged',
  PACKAGED = 'packaged',
  DELIVERING = 'delivered',
  DELIVERED = 'delivered',
}

export type ProjectStatesAndRoles = {
  projectStatus: ProjectStatus
  projectStateAsString: ProjectStateAsString
  description: string
  descriptionLabel: string
  availableToSellers: boolean
  availableToBuyers: boolean
  isActionAllowedToSellers: boolean
  isActionAllowedToBuyers: boolean
}

export enum ProjectRole {
  'Buyer' = 'Buyer',
  'Seller' = 'Seller',
}

export function IsBuyerPartyViewToProjectRole(isBuyerPartyView: boolean) {
  if (isBuyerPartyView) return ProjectRole.Buyer
  return ProjectRole.Seller
}

export class ProjectStateMachine {
  public GetAvailableProjectStatesForARole(
    role: ProjectRole,
    currentState: ProjectStatus
  ): ProjectStatesAndRoles[] {
    const allRoles = [
      {
        projectStatus: ProjectStatus.DEFINING,
        projectStateAsString: ProjectStateAsString.DEFINING,
        description: 'defining',
        descriptionLabel: this.getLabelByState(
          ProjectStateAsString.DEFINING,
          currentState
        ),
        availableToBuyers: true,
        isActionAllowedToBuyers: currentState < ProjectStatus.ORDERED,
        availableToSellers: true,
        isActionAllowedToSellers: currentState < ProjectStatus.ORDERED,
      },
      {
        projectStatus: ProjectStatus.REQUESTED,
        projectStateAsString: ProjectStateAsString.REQUESTED,
        description: 'requested',
        descriptionLabel: this.getLabelByState(
          ProjectStateAsString.REQUESTED,
          currentState
        ),
        availableToBuyers: true,
        isActionAllowedToBuyers: currentState <= ProjectStatus.REQUESTED,
        availableToSellers: true,
        isActionAllowedToSellers: currentState <= ProjectStatus.REQUESTED,
      },
      // {
      //   projectStatus: ProjectStatus.QUOTING,
      //   projectStateAsString: ProjectStateAsString.QUOTING,
      //   description: 'quoting',
      //   descriptionLabel: this.getLabelByState(ProjectStateAsString.QUOTING, currentState),
      //   availableToBuyers: false,
      //   isActionAllowedToBuyers: false,
      //   availableToSellers: true,
      //   isActionAllowedToSellers: currentState <= ProjectStatus.QUOTING,
      // },
      {
        projectStatus: ProjectStatus.QUOTED,
        projectStateAsString: ProjectStateAsString.QUOTED,
        description: 'quoted',
        descriptionLabel: this.getLabelByState(
          ProjectStateAsString.QUOTED,
          currentState
        ),
        availableToBuyers: true,
        isActionAllowedToBuyers: false,
        availableToSellers: true,
        isActionAllowedToSellers: currentState < ProjectStatus.ORDERED,
      },
      // {
      //   projectStatus: ProjectStatus.NEGOTIATING,
      //   projectStateAsString: ProjectStateAsString.NEGOTIATING,
      //   description: 'negotiating',
      //   descriptionLabel: this.getLabelByState(ProjectStateAsString.NEGOTIATING, currentState),
      //   availableToBuyers: false,
      //   isActionAllowedToBuyers: false,
      //   availableToSellers: true,
      //   isActionAllowedToSellers: currentState < ProjectStatus.ORDERED,
      // },
      {
        projectStatus: ProjectStatus.ORDERED,
        projectStateAsString: ProjectStateAsString.ORDERED,
        description: 'ordered',
        descriptionLabel: this.getLabelByState(
          ProjectStateAsString.ORDERED,
          currentState
        ),
        availableToBuyers: true,
        availableToSellers: true,
        isActionAllowedToBuyers: currentState >= ProjectStatus.QUOTED,
        isActionAllowedToSellers: currentState >= ProjectStatus.QUOTED,
      },
    ]

    if (role === ProjectRole.Seller) {
      return allRoles.filter((x) => x.availableToSellers)
    } else {
      return allRoles.filter((x) => x.availableToBuyers)
    }
  }

  getLabelByState = (
    stateNeeded: ProjectStateAsString,
    currentState: ProjectStatus
  ) =>
    currentState === ProjectStatus[stateNeeded]
      ? ProjectStatusLabelDuringCurrentState[stateNeeded]
      : currentState < ProjectStatus[stateNeeded]
      ? ProjectStatusLabelBeforeCurrentState[stateNeeded]
      : ProjectStatusLabelAfterCurrentState[stateNeeded]
}
