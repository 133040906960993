import { OnRequestChangeCallback } from 'services/APIs/BaseAPI'
import { InternalAPI } from 'services/APIs/InternalAPI/InternalAPI'
import {
  DownloadMaterialEstimatorRequest,
  MaterialSelectorDto,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { IBomItemActivityAPI } from './BomItemActivityController'

export class BomItemActivityAPI
  extends InternalAPI
  implements IBomItemActivityAPI
{
  constructor(
    partyId: string,
    projectId: string,
    private onRequestChange: OnRequestChangeCallback
  ) {
    super(
      `/api/parties/${partyId}/projects/${projectId}/activities`,
      onRequestChange
    )
  }

  public async GetBomItemActivityMaterials(
    workingStepType: WorkingStepType,
    selectedTokens?: string[]
  ) {
    return await this.GetAsync<MaterialSelectorDto>({
      id: 'GetBomItemActivityMaterials',
      relativePath: `/${workingStepType}/materials`,
      params: {
        selectedTokens,
      },
      onRequestChange: this.onRequestChange,
    })
  }

  public async SetBomItemActivityArticle(
    bomItemIdsAndActivities: Array<{
      bomItemId: string
      activityId: string
    }>,
    articleId: string
  ) {
    return await this.PutAsync<void>({
      id: 'SetBomItemActivityArticle',
      relativePath: `/article`,
      data: {
        bomItemIdsAndActivities,
        articleId,
      },
      onRequestChange: this.onRequestChange,
    })
  }

  public async SetBomItemActivityKeywords(
    bomItemIdsAndActivities: Array<{
      bomItemId: string
      activityId: string
    }>,
    keywords: string[]
  ) {
    return await this.PutAsync<void>({
      id: 'setActivityKeywords',
      relativePath: `/keywords`,
      data: {
        bomItemIdsAndActivities,
        keywords,
      },
      onRequestChange: this.onRequestChange,
    })
  }

  public async DownloadMaterialEstimator(
    req: DownloadMaterialEstimatorRequest
  ): Promise<boolean> {
    return await this.DownloadAsync(
      {
        id: 'DownloadMaterialEstimator',
        relativePath: `/download-material-estimator`,
        data: req,
        onRequestChange: this.onRequestChange,
      },
      true
    )
  }
}
