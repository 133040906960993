import { ArticleSelectorDialogProps } from 'components/Common/ArticleSelector/ArticleSelectorDialog'
import { AssemblySimplificationDialogProps } from 'components/Common/AssemblySimplification/AssemblySimplificationDialog'
import { IssuesDialogProps } from 'components/ProjectPage/BillOfMaterials/Rows/PartType/IssuesDialog'
import { BatchEditBomItemsDialogProps } from 'features/BillOfMaterials/BatchEditRowsDialog/BatchEditRowsDialog'
import { CuttingPlanDialogProps } from 'features/BillOfMaterials/MaterialHeader/ActiveCuttingPlanDialog'
import { BomItemDetailsDrawerProps } from 'features/BomItemDetailsDrawer/BomItemDetailsDrawer'
import { ConfirmationDialogProps } from 'features/ConfirmationDialog/ConfirmationDialog'
import { CostPriceDetailsProps } from 'features/CostPriceDetails/CostPriceDetailsDialog'
import { EventLogsDialogProps } from 'features/EventLogs/components/EventLogsDialog'
import { ProjectOperationsProps } from 'features/GeometryLogs/ProjectOperationsLogsDrawer'
import { PurchasingPartDialogProps } from 'features/PurchasingPartDetails/PurchasingPartDialog'
import { SalesRepresentativeDialogProps } from 'features/SalesRepresentative/components/SalesRepresentativeDialog'
import { SupportContactDialogProps } from 'features/SupportContactDialog/SupportContactDialog'
import { AdditionalWorkingStepsDialogProps } from 'features/WorkingSteps/AdditionalWorkingSteps/Dialog/AdditionalWorkingStepsDialogProps'
import { AdditionalWorkingStepsDialogPropsV2 } from 'features/WorkingSteps/AdditionalWorkingStepsV2/DialogV2/AdditionalWorkingStepsDialogPropsV2'
import { Action } from 'typesafe-actions'

export enum ProjectViews {
  NotInitialized = 0,
  DashboardView = 1,
  GridView = 2,
  BoMBoLView = 3,
  MaterialsView = 4,
  RoutesView = 5,
}

export type ModalTypes =
  | 'AdditionalWorkingSteps'
  // | 'AdditionalWorkingStepsV2'
  | 'ArticleSelector'
  | 'BomItemDetailsV2'
  | 'AssemblySimplification'
  | 'IssuesDialog'
  | 'BomItemDetailsDrawer'
  | 'EventLogs'
  | 'SalesRepresentative'
  | 'SupportContact'
  | 'CostPriceDetails'
  | 'CuttingPlanDetails'
  | 'ConfirmationDialog'
  | 'BatchEditBomItems'
  | 'ProjectOperations'
  | 'PurchasingPartDialog'
  | 'ProjectDetailsDialog'
  | 'CopyPasteDrawer'

export type ModalTypeProps =
  | ArticleSelectorDialogProps
  | AssemblySimplificationDialogProps
  | IssuesDialogProps
  | EventLogsDialogProps
  | SalesRepresentativeDialogProps
  | SupportContactDialogProps
  | CostPriceDetailsProps
  | CuttingPlanDialogProps
  | ConfirmationDialogProps
  | BatchEditBomItemsDialogProps
  | ProjectOperationsProps
  | BomItemDetailsDrawerProps
  | PurchasingPartDialogProps
  | AdditionalWorkingStepsDialogProps
  | AdditionalWorkingStepsDialogPropsV2

export type ModalTypePropsMap = {
  AdditionalWorkingSteps: AdditionalWorkingStepsDialogProps
  AdditionalWorkingStepsV2: AdditionalWorkingStepsDialogPropsV2
  ArticleSelector: ArticleSelectorDialogProps
  BomItemDetailsV2: BomItemDetailsDrawerProps
  AssemblySimplification: AssemblySimplificationDialogProps
  IssuesDialog: IssuesDialogProps
  BomItemDetailsDrawer: BomItemDetailsDrawerProps
  EventLogs: EventLogsDialogProps
  SalesRepresentative: SalesRepresentativeDialogProps
  SupportContact: SupportContactDialogProps
  CostPriceDetails: CostPriceDetailsProps
  CuttingPlanDetails: CuttingPlanDialogProps
  ConfirmationDialog: ConfirmationDialogProps
  BatchEditBomItems: BatchEditBomItemsDialogProps
  ProjectOperations: ProjectOperationsProps
  PurchasingPartDialog: PurchasingPartDialogProps
  ProjectDetailsDialog: ProjectOperationsProps
}

export type UIState = {
  Modals?: {
    CurrentModal: ModalTypes
    ModalProps: ModalTypeProps
  }
}

export enum UIActionNames {
  UploadProgress = 'ui/update_upload_progress',
  DeleteProgress = 'ui/delete_upload_progress',
  ChangeProjectView = 'ui/change_project_view',
  CollapseAllHeaders = 'ui/collapse_all_headers',
  ExpandAllHeaders = 'ui/expand_all_headers',
  CloseModal = 'CloseModal',
  OpenModal = 'OpenModal',
}

export interface ChangeProjectView extends Action {
  type: UIActionNames.ChangeProjectView
  view: ProjectViews
}

export interface SetUploadProgress extends Action {
  type: UIActionNames.UploadProgress
  operation: string
  progress: number
}

export interface DeleteUploadProgress extends Action {
  type: UIActionNames.DeleteProgress
  operation: string
}

export interface CollapseAllHeaders extends Action {
  type: UIActionNames.CollapseAllHeaders
}

export interface ExpandAllHeaders extends Action {
  type: UIActionNames.ExpandAllHeaders
}

export interface OpenModal extends Action {
  type: UIActionNames.OpenModal
  modalType: ModalTypes
  modalProps: ModalTypeProps
}

export interface CloseModal extends Action {
  type: UIActionNames.CloseModal
}

export type UIActionTypes =
  | SetUploadProgress
  | DeleteUploadProgress
  | ChangeProjectView
  | CollapseAllHeaders
  | ExpandAllHeaders
  | OpenModal
  | CloseModal
