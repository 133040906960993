// import { WorkingStepType } from 'services/APIs/InternalAPI/internal-api.contracts'
import { BomItemType } from 'model/Project/BoMItemRow'
import { ProjectViews } from 'store/UI/UITypes'
import { ProjectState } from '../../../store/Project/ProjectTypes'

export const projectInitialState: ProjectState = {
  currentView: ProjectViews.NotInitialized,
  activeProject: undefined,

  // the bom items data
  assemblyHeaders: undefined,
  assemblyInstances: undefined,
  partTypes: undefined,
  partInstances: undefined,
  materialHeaders: undefined,
  routingHeaders: undefined,

  priceSummaries: undefined,

  // handles all the ids of bom items
  assemblyHeadersIds: [],
  materialHeadersIds: [],
  assemblyInstancesIds: [],
  partTypeIds: [],
  partInstanceIds: [],
  routingHeadersIds: [],

  // the filtered headers, build bom item lists from this
  filteredAssemblyHeaderIds: [],
  filteredAssemblyInstanceIds: [],
  filteredMaterialHeaderIds: [],
  filteredRoutingHeaderIds: [],

  projectOperations: {
    shallUpdateSummary: true,
    shallUpdateOperations: true,
  },

  searchedBomItemPointer: {
    id: undefined,
    type: BomItemType.partType,
  },

  selectedBomItemPointers: [],
  selectedHeaderPointers: [],
  operationPending: false,
  currentOperations: {},
  focusedBomItemPointer: {
    id: undefined,
    type: undefined,
  },

  filters: {
    byWorkingStep: {
      filter: {
        type: 'WorkingStepsFilter',
        availableFilters: [],
        enabledFilters: [],
        disabledFilters: [],
      },
      active: false,
    },
    byMaterialKeywords: {
      filter: {
        type: 'MaterialKeywordsFilter',
        disabledFilters: undefined,
        enabledFilters: undefined,
        availableFilters: undefined,
        showIfAll: localStorage.getItem('material-filter:showIfAll') === 'true',
      },
      active: false,
    },
    byIssues: {
      filter: {
        type: 'IssuesFilter',
        disabledFilters: [],
        availableFilters: undefined,
      },
      active: false,
    },
    byPartTypeId: {
      filter: {
        type: 'PartTypeIdFilter',
        enabledFilters: [],
      },
      active: false,
    },
    byArticleId: {
      filter: {
        type: 'ArticleIdFilter',
        enabledFilters: [],
      },
      active: false,
    },
    byResourceId: {
      filter: {
        type: 'ResourceIdFilter',
        enabledFilters: [],
      },
      active: false,
    },
    byManufacturability: {
      filter: {
        type: 'ManufacturabilityCheckFilter',
        manufacturabilityStatus: undefined,
      },
      active: false,
    },
    byErpError: {
      filter: {
        type: 'ErpErrorFilter',
      },
      active: false,
    },
  },
  showAllComments: undefined,
  showAllIssues: undefined,
}
