import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { AnalyticsBrowser } from '@segment/analytics-next'
import LogRocket from 'logrocket'
import { Profile } from 'model/User'
import { store } from 'store/configureStore'

export class SegmentService {
  private static currentInstance: SegmentService
  private partyId: string
  private email: string
  private currentPage: string
  private analytics: AnalyticsBrowser

  public static getInstance() {
    if (!SegmentService.currentInstance) {
      SegmentService.currentInstance = new SegmentService()
    }
    return SegmentService.currentInstance
  }

  private constructor() {
    if (import.meta.env.VITE_SEGMENT_KEY) {
      try {
        this.analytics = AnalyticsBrowser.load({
          writeKey: import.meta.env.VITE_SEGMENT_KEY,
        })
      } catch (err) {
        console.error('unable to load segment', err)
      }
    }
  }

  public page(name: string) {
    if (this.currentPage === name) return

    this.currentPage = name

    this.analytics?.page(name, {
      partyId: this.partyId,
      email: this.email,
    })
  }

  public identify(userId: string, email: string, partyId: string) {
    this.analytics?.identify(userId, {
      email: email,
      partyId: partyId,
    })

    this.partyId = partyId
    this.email = email
  }
}

export class TelemetryService {
  private static currentInstance: TelemetryService

  public readonly reactPlugin: ReactPlugin

  private appInsights: ApplicationInsights

  public static getInstance() {
    if (!TelemetryService.currentInstance)
      TelemetryService.currentInstance = new TelemetryService()

    return this.currentInstance
  }

  private constructor() {
    this.reactPlugin = new ReactPlugin()
  }

  initialize() {
    const instrumentationKey = import.meta.env.VITE_INSTRUMENTATION_KEY

    if (!instrumentationKey) return null

    const environment = import.meta.env.PROD ? 'prod' : 'local'

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        extensions: [this.reactPlugin as any],
        enableAutoRouteTracking: true,
        appId: `FrontEnd - ${environment}`,
        disableTelemetry: true,
      },
    })

    TelemetryService.getInstance().appInsights?.loadAppInsights()
    TelemetryService.getInstance().appInsights?.addTelemetryInitializer(
      (item) => {
        item.tags['ai.cloud.role'] = 'FrontEnd'
        item.tags['ai.cloud.roleInstance'] = `FrontEnd - ${environment}`
      }
    )
  }

  public logError(error: unknown) {
    try {
      console.error('logError:', error)

      const appState = store.getState()
      TelemetryService.getInstance()?.appInsights?.trackException({
        exception: error as Error,
        properties: {
          page: window.location.pathname + window.location.search,
        },
      })

      LogRocket.captureException(error as Error, {
        extra: {
          partyId: appState?.user?.organizationContext?.id,
          partyName: appState?.user?.organizationContext?.name,
          tenantId: appState?.user?.tenantId,
        },
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  public IdentifyUser(user: Partial<Profile>) {
    if (!user) {
      return
    }

    try {
      this.appInsights?.setAuthenticatedUserContext(user.id, user.id, true)

      LogRocket.identify(user.email, {
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
      })

      window.Appcues?.identify(user.email, {
        accountId: user.auth0Id,
        firstName: user.firstName,
        lastName: user.lastName,
      })
    } catch (err) {
      console.error('unable setup telemetry for user', user, err)
      LogRocket.captureException(err as Error)
    }
  }

  public TrackEvent(
    eventName: string,
    properties?: Record<
      string,
      string | number | boolean | string[] | number[] | boolean[]
    >
  ) {
    // alert(`${eventName}: ${JSON.stringify(properties)}`)

    console.info(eventName, properties)
    LogRocket?.track(eventName, properties)
  }
}
