import cadex from '@cadexchanger/web-toolkit'
import { SelectedGeometryPointsCollector } from './SelectedFacePointsCollector'
import { SelectedPointsCollector } from './SelectedPointsCollector'

export enum MeasurementMode {
  None = -1,
  TwoPointDistance = 0,
  ThreePointAngle = 1,
  Face = 2,
}

export class MeasurementsManager extends cadex.ModelPrs_InputHandler {
  scene: cadex.ModelPrs_Scene
  selectedMeasurements: cadex.ModelPrs_SceneNode[]
  selectedPoints: cadex.ModelData_Point[]
  measurementMode: MeasurementMode
  fontSize: number
  lengthUnit: cadex.Base_LengthUnit
  angleUnit: cadex.Base_AngleUnit
  measurementsFactory: cadex.ModelPrs_MeasurementFactory
  measurementsSceneNodeFactory: cadex.ModelPrs_SceneNodeFactory
  measurementsRootNode: cadex.ModelPrs_SceneNode

  constructor(theScene: cadex.ModelPrs_Scene) {
    super()
    this.scene = theScene

    this.selectedMeasurements = []
    this.selectedPoints = []

    this.measurementMode = MeasurementMode.TwoPointDistance
    this.fontSize = 10
    this.lengthUnit = cadex.Base_LengthUnit.Base_LU_Millimeters
    this.angleUnit = cadex.Base_AngleUnit.Base_AU_Degrees

    this.measurementsFactory = new cadex.ModelPrs_MeasurementFactory()
    this.measurementsSceneNodeFactory = new cadex.ModelPrs_SceneNodeFactory()

    this.measurementsRootNode = new cadex.ModelPrs_SceneNode()
    this.measurementsRootNode.displayMode = cadex.ModelPrs_DisplayMode.Shaded
    this.measurementsRootNode.selectionMode = cadex.ModelPrs_SelectionMode.Node
    this.measurementsRootNode.appearance = new cadex.ModelData_Appearance(
      cadex.ModelData_ColorObject.fromHex(0x000)
    )
    this.scene.addRoot(this.measurementsRootNode)
    this.scene.update()
  }

  // //@ts-expect-error - this error after updating the lib to version 3.24.0
  // get isAcceptKeyEvents() {
  //   return true
  // }

  clear() {
    // To release internal data previously created, the factories are just re-created
    this.measurementsFactory = new cadex.ModelPrs_MeasurementFactory()
    this.measurementsSceneNodeFactory = new cadex.ModelPrs_SceneNodeFactory()
    this.measurementsRootNode.removeChildNodes()
  }

  public async checkSelectedItems(args: cadex.ModelPrs_SelectionChangedEvent) {
    args.added.forEach(async (selectedItem) => {
      if (selectedItem.isWholeSelectedNode) {
        this.selectedMeasurements.push(selectedItem.node)
      } else {
        if (this.measurementMode === MeasurementMode.Face) {
          // console.log('selectedItem', selectedItem.node.geometry.boundingBox())

          const geometryVisitor = new SelectedGeometryPointsCollector()

          selectedItem.node.geometry.accept(geometryVisitor)

          setTimeout(() => {
            const pointsToCreate: Array<cadex.ModelData_Point>[] = []
            let index = 0

            let point1: cadex.ModelData_Point
            geometryVisitor.points?.forEach((point) => {
              if (++index % 2) {
                pointsToCreate.push([point1, point])
              } else {
                point1 = point
              }
            })

            // console.log('points to create', pointsToCreate)

            pointsToCreate.forEach((points) => {
              this.createDistanceMeasurement(points[0], points[1])
            })

            this.scene.update()
          }, 5000)

          // const facePointsCollector = new SelectedFacePointsCollector()
          // for (const entity of selectedItem.entities()) {
          //   await entity.accept(facePointsCollector)
          // }
        } else {
          const aSelectedPointsCollector = new SelectedPointsCollector()

          for (const aSelectedEntity of selectedItem.entities()) {
            aSelectedEntity.accept(aSelectedPointsCollector)
          }

          aSelectedPointsCollector.points.forEach((thePoint) => {
            const aTransformation = selectedItem.node.combinedTransformation

            if (aTransformation) {
              thePoint.transform(selectedItem.node.combinedTransformation)
            }

            this.selectedPoints.push(thePoint)
          })
        }
      }
    })

    args.removed.forEach((theSelectedItem) => {
      if (this.measurementMode === MeasurementMode.Face) {
      } else {
        if (theSelectedItem.isWholeSelectedNode) {
          const anIndex = this.selectedMeasurements.findIndex(
            (theNode) => theNode === theSelectedItem.node
          )

          this.selectedMeasurements.splice(anIndex, 1)
        } else {
          const aSelectedPointsCollector = new SelectedPointsCollector()

          for (const aSelectedEntity of theSelectedItem.entities()) {
            aSelectedEntity.accept(aSelectedPointsCollector)
          }

          aSelectedPointsCollector.points.forEach((thePoint) => {
            const aTransformation = theSelectedItem.node.combinedTransformation
            if (aTransformation) {
              thePoint.transform(theSelectedItem.node.combinedTransformation)
            }

            const anIndex = this.selectedPoints.findIndex((theSelectedPoint) =>
              theSelectedPoint.isEqual(thePoint)
            )

            if (anIndex !== -1) {
              this.selectedPoints.splice(anIndex, 1)
            }
          })
        }
      }
    })

    if (
      this.measurementMode === MeasurementMode.TwoPointDistance &&
      this.selectedPoints.length === 2
    ) {
      this.createDistanceMeasurement(
        this.selectedPoints[0],
        this.selectedPoints[1]
      )
    }
    if (
      this.measurementMode === MeasurementMode.ThreePointAngle &&
      this.selectedPoints.length === 3
    ) {
      this.createAngleMeasurement(
        this.selectedPoints[0],
        this.selectedPoints[1],
        this.selectedPoints[2]
      )
    }
  }

  async createDistanceMeasurement(
    thePoint1: cadex.ModelData_Point,
    thePoint2: cadex.ModelData_Point
  ) {
    const aDistanceMeasurement =
      this.measurementsFactory.createDistanceFromPoints(thePoint1, thePoint2)
    if (!aDistanceMeasurement) {
      return
    }
    this.scene.selectionManager.deselectAll()

    // find the extension line direction
    // the main idea is to use direction aligned with vector from scene bbox center to measurement points.
    const aBBoxCenter = this.scene.boundingBox.getCenter()

    // use center of measurement reference point for extension line direction
    const anExtensionLineDirection = new cadex.ModelData_Vector()
      .addVectors(thePoint1, thePoint2)
      .multiplyScalar(0.5)
      .subtract(aBBoxCenter)
      .normalize()

    // next try to align annotation direction with X, Y, Z axes.
    const aP1P2Direction = new cadex.ModelData_Vector()
      .subtractVectors(thePoint1, thePoint2)
      .normalize()
    const aDirXAbs = Math.abs(anExtensionLineDirection.x)
    const aDirYAbs = Math.abs(anExtensionLineDirection.y)
    const aDirZAbs = Math.abs(anExtensionLineDirection.z)

    if (aDirZAbs && aDirZAbs >= aDirXAbs && aDirZAbs >= aDirYAbs) {
      if (
        Math.abs(aP1P2Direction.x) < 1e-5 &&
        Math.abs(aP1P2Direction.y) < 1e-5
      ) {
        // degenerate case, choose X axis
        anExtensionLineDirection.z = 0
      } else {
        anExtensionLineDirection.x = 0
      }
      anExtensionLineDirection.y = 0
    } else if (
      aDirXAbs > 1e-5 &&
      aDirXAbs >= aDirYAbs &&
      aDirXAbs >= aDirZAbs
    ) {
      if (
        Math.abs(aP1P2Direction.y) < 1e-5 &&
        Math.abs(aP1P2Direction.z) < 1e-5
      ) {
        // degenerate case, choose Z axis
        anExtensionLineDirection.x = 0
      } else {
        anExtensionLineDirection.z = 0
      }
      anExtensionLineDirection.y = 0
    } else if (aDirYAbs > 1e-5) {
      if (
        Math.abs(aP1P2Direction.x) < 1e-5 &&
        Math.abs(aP1P2Direction.z) < 1e-5
      ) {
        // degenerate case, choose Z axis
        anExtensionLineDirection.y = 0
      } else {
        anExtensionLineDirection.z = 0
      }
      anExtensionLineDirection.x = 0
    } else {
      // default is Z axis
      anExtensionLineDirection.setCoord(0, 0, 1)
    }

    // orthogonalize extension line direction
    const anOrthogonalizedExtensionLineDirection =
      cadex.ModelData_Direction.fromXYZ(aP1P2Direction)
        .cross(anExtensionLineDirection)
        .cross(aP1P2Direction)

    // For better UX place measurement label out of model bbox

    // find the max distance between points to BBox boundaries in chosen direction
    const tmp = new cadex.ModelData_Vector()
    const aBBoxMinCorner = this.scene.boundingBox.minCorner
    const aBBoxMaxCorner = this.scene.boundingBox.maxCorner
    let anExtensionLineLength = Math.max(
      tmp
        .subtractVectors(aBBoxMinCorner, thePoint1)
        .dot(anOrthogonalizedExtensionLineDirection),
      tmp
        .subtractVectors(aBBoxMinCorner, thePoint1)
        .dot(anOrthogonalizedExtensionLineDirection),
      tmp
        .subtractVectors(aBBoxMinCorner, thePoint1)
        .dot(anOrthogonalizedExtensionLineDirection),
      tmp
        .subtractVectors(aBBoxMinCorner, thePoint1)
        .dot(anOrthogonalizedExtensionLineDirection),
      tmp
        .subtractVectors(aBBoxMaxCorner, thePoint1)
        .dot(anOrthogonalizedExtensionLineDirection),
      tmp
        .subtractVectors(aBBoxMaxCorner, thePoint1)
        .dot(anOrthogonalizedExtensionLineDirection),
      tmp
        .subtractVectors(aBBoxMaxCorner, thePoint1)
        .dot(anOrthogonalizedExtensionLineDirection),
      tmp
        .subtractVectors(aBBoxMaxCorner, thePoint1)
        .dot(anOrthogonalizedExtensionLineDirection),
      tmp
        .subtractVectors(aBBoxMinCorner, thePoint2)
        .dot(anOrthogonalizedExtensionLineDirection),
      tmp
        .subtractVectors(aBBoxMinCorner, thePoint2)
        .dot(anOrthogonalizedExtensionLineDirection),
      tmp
        .subtractVectors(aBBoxMinCorner, thePoint2)
        .dot(anOrthogonalizedExtensionLineDirection),
      tmp
        .subtractVectors(aBBoxMinCorner, thePoint2)
        .dot(anOrthogonalizedExtensionLineDirection),
      tmp
        .subtractVectors(aBBoxMaxCorner, thePoint2)
        .dot(anOrthogonalizedExtensionLineDirection),
      tmp
        .subtractVectors(aBBoxMaxCorner, thePoint2)
        .dot(anOrthogonalizedExtensionLineDirection),
      tmp
        .subtractVectors(aBBoxMaxCorner, thePoint2)
        .dot(anOrthogonalizedExtensionLineDirection),
      tmp
        .subtractVectors(aBBoxMaxCorner, thePoint2)
        .dot(anOrthogonalizedExtensionLineDirection)
    )

    if (anExtensionLineLength < 0) {
      anExtensionLineLength *= -1
    }

    // add addition offset for better UX
    anExtensionLineLength += 3 * this.fontSize

    aDistanceMeasurement.fontSize = this.fontSize
    aDistanceMeasurement.lengthUnit = this.lengthUnit
    aDistanceMeasurement.extensionLineDirection =
      anOrthogonalizedExtensionLineDirection
    aDistanceMeasurement.extensionLineLength = anExtensionLineLength
    aDistanceMeasurement.extensionOverhangLength = 0.4 * this.fontSize

    const aDistanceMeasurementNode =
      this.measurementsSceneNodeFactory.createNodeFromMeasurement(
        aDistanceMeasurement
      )
    this.measurementsRootNode.addChildNode(aDistanceMeasurementNode)
    await this.scene.update()
  }

  /**
   * @param {cadex.ModelData_Point} thePoint1
   * @param {cadex.ModelData_Point} thePoint2
   * @param {cadex.ModelData_Point} thePoint3
   */
  async createAngleMeasurement(
    thePoint1: cadex.ModelData_Point,
    thePoint2: cadex.ModelData_Point,
    thePoint3: cadex.ModelData_Point
  ) {
    const anAngleMeasurement = this.measurementsFactory.createAngleFromPoints(
      thePoint1,
      thePoint2,
      thePoint3
    )
    if (!anAngleMeasurement) {
      return
    }
    this.scene.selectionManager.deselectAll()

    anAngleMeasurement.fontSize = this.fontSize
    anAngleMeasurement.angleUnit = this.angleUnit
    anAngleMeasurement.extensionLineLength = 100 * this.fontSize
    anAngleMeasurement.extensionOverhangLength = 0.4 * this.fontSize

    const anAngleMeasurementNode =
      this.measurementsSceneNodeFactory.createNodeFromMeasurement(
        anAngleMeasurement
      )
    this.measurementsRootNode.addChildNode(anAngleMeasurementNode)
    await this.scene.update()
  }

  keyDown(theEvent: cadex.ModelPrs_KeyboardInputEvent): boolean {
    if (theEvent.code === 'Delete') {
      this.selectedMeasurements.forEach((theNode) => {
        this.measurementsRootNode.removeChildNode(theNode)
      })
      if (this.selectedMeasurements.length > 0) {
        this.selectedMeasurements.length = 0
        this.scene.update()
      }
      return true
    }
    return false
  }

  setMeasurementMode(mode: MeasurementMode) {
    this.measurementMode = mode
  }
}
