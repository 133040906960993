import { BomItemType } from 'model/Project/BoMItemRow'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BoMItemActivityDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from '../bomItemSelector'

export const bomItemRawMaterialActivitySelector =
  (bomItemPointer: BomItemPointer) =>
  (state: Partial<RootAppState>): BoMItemActivityDto => {
    const bomItem = bomItemSelector(state, bomItemPointer)

    if (!bomItem) {
      return null
    }

    switch (bomItem.type) {
      case BomItemType.partType:
      case BomItemType.partInstance: {
        const activityUsingRawMaterial = bomItem.activities.find(
          (x) => x.usesRawMaterial
        )
        return {
          id: activityUsingRawMaterial?.id ?? '',
          article: {
            id: bomItem.rawMaterialSummary?.id,
            description: bomItem.rawMaterialSummary?.description,
          },
          keywords: bomItem.rawMaterialSummary?.tokens,
          primaryWorkingStep: activityUsingRawMaterial?.primaryWorkingStep,
          secondaryWorkingStep: activityUsingRawMaterial?.secondaryWorkingStep,
          resource: activityUsingRawMaterial?.resource,
          isPrimaryWorkingStep: true,
          supportsArticle: true,
          usesRawMaterial: true,
          comment: activityUsingRawMaterial?.comment,
        }
      }
      case BomItemType.materialHeader: {
        return {
          id: bomItem.materialId,
          article: {
            id: bomItem.materialId,
            description: bomItem.materialDescription,
          },
          keywords: bomItem.materialKeywords,
          primaryWorkingStep: bomItem.workingStep.primaryWorkingStep,
          secondaryWorkingStep: bomItem.workingStep.secondaryWorkingStep,
          resource: bomItem.workingStep.resource,
          isPrimaryWorkingStep: bomItem.workingStep.isPrimaryWorkingStep,
          notRemovable: bomItem.workingStep.notRemovable,
          supportsArticle: true,
          usesRawMaterial: bomItem.workingStep.usesRawMaterial,
        }
      }
      default: {
        console.error(
          `bomItemRawMaterialActivitySelector for type ${bomItem.type} is not defined`
        )
        return null
      }
    }
  }
