import { BomItemPointer } from 'model/Project/BomItemPointer'
import { BomItemType } from 'model/Project/BoMItemRow'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'

export const bomItemQuantityIsEditableSelector =
  (bomItemPointer: BomItemPointer) => (state: RootAppState) => {
    const bomItem = bomItemSelector(state, bomItemPointer)

    if (bomItem?.type === BomItemType.partType) {
      // part type quantity is editable if parent assembly isn't a collection of individual parts
      const assembly = bomItemSelector(state, bomItem.parentBomItemPointer)

      if (
        assembly?.type === BomItemType.assemblyType ||
        assembly?.type === BomItemType.assemblyInstance
      ) {
        return assembly.hasGeometry
      }
      return false
    }

    if (bomItem?.type === BomItemType.assemblyType) {
      // assembly type quantity is always editable
      return true
    }

    if (bomItem?.type === BomItemType.assemblyInstance) {
      // assembly instances quantities are not editable
      return false
    }

    return false
  }
