import { BaseController } from 'controllers/BaseController'
import { BomItemController } from 'controllers/Project/BomItemController'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import {
  DownloadMaterialEstimatorRequest,
  KeywordDto,
  MaterialSelectorDto,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { GetContext } from 'store/storeUtils'
import { BomItemActivityAPI } from './BomItemActivityAPI'

export interface IBomItemActivityAPI {
  GetBomItemActivityMaterials: (
    workingStepType: WorkingStepType,
    selectedTokens?: string[]
  ) => Promise<MaterialSelectorDto>
  SetBomItemActivityArticle: (
    bomItemIdsAndActivities: Array<{
      bomItemId: string
      activityId: string
    }>,
    articleId: string
  ) => Promise<void>
  SetBomItemActivityKeywords: (
    bomItemIdsAndActivities: Array<{
      bomItemId: string
      activityId: string
    }>,
    keywords: string[]
  ) => Promise<void>
  DownloadMaterialEstimator: (
    req: DownloadMaterialEstimatorRequest
  ) => Promise<boolean>
}

export class BomItemActivityController extends BaseController<IBomItemActivityAPI> {
  constructor() {
    super((onRequestChange) => {
      const { partyId, projectId } = GetContext()
      return new BomItemActivityAPI(partyId, projectId, onRequestChange)
    })
  }

  public async GetBomItemActivityMaterials(
    workingStepType: WorkingStepType,
    selectedTokens?: string[]
  ) {
    try {
      return await this.api.GetBomItemActivityMaterials(
        workingStepType,
        selectedTokens
      )
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async SetBomItemActivityArticle(
    bomItemIdsAndActivities: Array<{
      bomItemPointer: BomItemPointer
      activityId: string
    }>,
    articleId: string,
    selectedKeywords: KeywordDto[],
    isRawMaterial: boolean
  ) {
    try {
      // if it's a raw material (a property of bomItem), should use the bomItem endpoint
      if (isRawMaterial) {
        const rawMaterialController = new BomItemController()
        return await rawMaterialController.SetKeywordsOrArticleToParts({
          bomItemPointers: bomItemIdsAndActivities.map((x) => x.bomItemPointer),
          articleId: articleId,
          keywords: Object.values(selectedKeywords).flat(),
        })
      } else {
        if (articleId) {
          return await this.api.SetBomItemActivityArticle(
            bomItemIdsAndActivities.map((x) => ({
              activityId: x.activityId,
              bomItemId: x.bomItemPointer.id,
            })),
            articleId
          )
        } else {
          return await this.api.SetBomItemActivityKeywords(
            bomItemIdsAndActivities.map((x) => ({
              bomItemId: x.bomItemPointer.id,
              activityId: x.activityId,
            })),
            selectedKeywords.map((x) => x.originalKeyword)
          )
        }
      }
    } catch (err) {
      throw this.HandleError(err)
    }
  }

  public async SetBomItemActivityKeywords(
    bomItemIdsAndActivities: Array<{
      bomItemPointer: BomItemPointer
      activityId: string
    }>,
    keywords: Record<string, KeywordDto[]>,
    isRawMaterial: boolean
  ) {
    try {
      this.onRequestChange({
        id: 'SetBomItemActivityKeywords',
        isLoading: true,
      })
      if (isRawMaterial) {
        // if it's a raw material (a property of bomItem), should use the bomItem endpoint
        const rawMaterialController = new BomItemController()
        return await rawMaterialController.SetKeywordsOrArticleToParts({
          bomItemPointers: bomItemIdsAndActivities.map((x) => x.bomItemPointer),
          keywords: Object.values(keywords).flat(),
        })
      } else {
        // should use the activity keywords
        return await this.api.SetBomItemActivityKeywords(
          bomItemIdsAndActivities.map((x) => {
            return {
              bomItemId: x.bomItemPointer.id,
              activityId: x.activityId,
            }
          }),
          Object.values(keywords)
            .flat()
            .map((x) => x.originalKeyword)
        )
      }
    } catch (err) {
      throw this.HandleError(err)
    } finally {
      this.onRequestChange({
        id: 'SetBomItemActivityKeywords',
        isLoading: false,
      })
    }
  }

  public async DownloadMaterialEstimatorRequest(
    req: DownloadMaterialEstimatorRequest
  ) {
    try {
      return await this.api.DownloadMaterialEstimator(req)
    } catch (err) {
      throw this.HandleError(err)
    }
  }
}
