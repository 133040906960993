/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import cadex from '@cadexchanger/web-toolkit'
import { SceneDataStore } from '../store/SceneDataStore'
import { Rh24SceneNode } from '../store/types/Rh24SceneNode'

export class PaintEntityVisitor extends cadex.ModelPrs_PickedEntityVisitor {
  public formattedEntity: string
  public color: cadex.ModelData_ColorObject
  public shapeType: string
  public shapeName: string
  public shapeId: number

  constructor(color: cadex.ModelData_ColorObject, sceneStore: SceneDataStore) {
    super()
    this.formattedEntity = null
    this.color = color
  }

  private getPartName(repNode: Rh24SceneNode) {
    return `${repNode.nodeType} ${repNode.name}`
  }

  visitPickedClipPlaneEntity(
    entity: cadex.ModelPrs_PickedClipPlaneEntity
  ): void {
    this.formattedEntity = 'ClipPlane'
    return
  }
  visitPickedNodeEntity(entity: cadex.ModelPrs_PickedNodeEntity): void {
    return
  }
  visitPickedPolyShapeEntity(
    entity: cadex.ModelPrs_PickedPolyShapeEntity
  ): void {
    return
  }
  visitPickedPolyVertexEntity(
    entity: cadex.ModelPrs_PickedPolyVertexEntity
  ): void {
    return
  }
  visitPickedShapeEntity(entity: cadex.ModelPrs_PickedShapeEntity): void {
    const node = entity.node as Rh24SceneNode

    if (node.nodeType === 'FACE') {
      if (this.color) {
        this.shapeType = 'FACE'
        node.parent.invalidate()
      }

      return
    }

    console.assert(node.representation, 'Node has no representation')
    if (node.representation) {
      const partName = this.getPartName(node)

      const brepRep = node.representation as cadex.ModelData_BRepRepresentation
      const shapeId = brepRep.shapeId(entity.shape)
      const shapeName = brepRep.shapeName(entity.shape)
      const shapeType = Object.keys(cadex.ModelData_ShapeType).find(
        (n) => cadex.ModelData_ShapeType[n] === entity.shape.type
      )

      this.formattedEntity = `${shapeType} - ${shapeId}: ${shapeName}`

      this.shapeId = shapeId
      this.shapeName = shapeName
      this.shapeType = shapeType

      console.assert(this.color, 'Color is not set')
      if (this.color) {
        brepRep.setShapeAppearance(
          entity.shape,
          new cadex.ModelData_Appearance(this.color)
        )
        node.invalidate()
      }
    }
  }
}
