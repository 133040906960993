import i18n from 'i18next'
import {
  BasicToastrOptions,
  ConfirmToastrOptions,
  toastr,
} from 'react-redux-toastr'
import { BuiltInThemes, darkBlueTheme, rh24WebAppTheme } from 'utils/appTheme'
import { Issue } from '../../model/IssueContext'
import { AuthService } from '../../services/auth'
import { APICallArgs, ApplicationActionTypes } from './appState'

export function APICall(configuration: APICallArgs) {
  return {
    type: ApplicationActionTypes.API,
    payload: configuration,
  }
}

export const ToggleNewItemsModal = (open?: boolean) => (dispatch) => {
  dispatch({
    type: ApplicationActionTypes.ToggleNewItemsModal,
    open,
  })
}

export const GetAccessToken = async () => {
  const promise = new Promise<string | undefined>(async (resolve) => {
    let retries = 0
    let authToken = undefined
    do {
      authToken = await AuthService.GetCurrent()?.GetAccessToken()
      !authToken && (await new Promise((r) => setTimeout(r, 1000)))
      retries++
    } while (retries < 3 && !authToken)

    resolve(authToken)
  })

  return promise
}

export const StartNetwork = (payload = 'global') => ({
  type: ApplicationActionTypes.StartNetwork,
  payload,
})

export const EndNetwork = (payload = 'global') => ({
  type: ApplicationActionTypes.EndNetwork,
  payload,
})

export const SetSignalRInfo = (payload: {
  [connectionGroup: string]: {
    isConnected: boolean
    isReconnecting: boolean
    errorMessage?: string
  }
}) => ({
  type: ApplicationActionTypes.SignalRSubscriptionInfo,
  payload,
})

export const ShowUserFeedbackForm = ({
  show,
  issue,
}: {
  show: boolean
  issue: Issue
}) => ({
  type: ApplicationActionTypes.ShowUserFeedbackForm,
  show: show,
  issue,
})

export const ShowNewProjectForm = (show: boolean, isSellerRole?: boolean) => ({
  type: ApplicationActionTypes.ShowNewProjectForm,
  show,
  isSellerRole,
})

export const SuccessMessage = (
  title: string,
  message: string,
  options: BasicToastrOptions = {}
) => {
  toastr.success(title, message, {
    className: 'qf-toastr qf-toastr-success',
    transitionIn: 'fadeIn',
    transitionOut: 'fadeOut',
    showCloseButton: false,
    ...options,
  })
}

export const InfoMessage = (
  title: string,
  message: string,
  options: BasicToastrOptions = {}
) => {
  toastr.info(title, message, {
    className: 'qf-toastr qf-toastr-info',
    transitionIn: 'fadeIn',
    transitionOut: 'fadeOut',
    showCloseButton: false,
    ...options,
  })
}

export const ConfirmMessage = (
  message: string,
  options: ConfirmToastrOptions = {}
) => {
  toastr.confirm(message, options)
}

export const WarningMessage = (
  title: string,
  message: string,
  options: BasicToastrOptions = {}
) => {
  toastr.warning(title, message, {
    className: 'qf-toastr qf-toastr-warning',
    transitionIn: 'fadeIn',
    transitionOut: 'fadeOut',
    showCloseButton: false,
    ...options,
  })
}

export const ErrorMessage = (
  title: string,
  message: string | unknown,
  options: BasicToastrOptions = {}
) => {
  console.error(`${title}: ${message}`)

  if (message?.toString().length > 200) {
    message = i18n.t(
      'errors:internal-error-try-later',
      'internal error, please retry in a few moments'
    )
  }

  toastr.error(title, message as string, {
    className: 'qf-toastr qf-toastr-error',
    transitionIn: 'fadeIn',
    transitionOut: 'fadeOut',
    showCloseButton: false,
    ...options,
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ServerErrorString = (err: any): string => {
  if (!err) {
    return null
  }

  if (err.validation) {
    return Object.keys(err.validation)
      .map(
        (validationKey) => `${validationKey}: ${err.validation[validationKey]}`
      )
      .join('\n')
  }

  const message =
    err['message'] || err['details'] || err['title'] || err.toString()

  return message
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ShowException = (title: string, err: any) => {
  if (!err) {
    return
  }

  console.error(err)
  ErrorMessage(title, ServerErrorString(err))
}

export const onMaterialSelectorToggleOpen = (isOpen: boolean) => ({
  type: ApplicationActionTypes.OpenMaterialSelector,
  isOpen,
})

export const onNotificationToggle = (shouldOpen = false) => ({
  type: ApplicationActionTypes.ToggleNotifications,
  shouldOpen,
})

export const SetCurrentTabIndex = (tabIndex: number) => ({
  type: ApplicationActionTypes.SetCurrentTabIndex,
  tabIndex,
})

export const SetLoadingMessage = (message: string) => ({
  type: ApplicationActionTypes.SetLoadingMessage,
  message,
})

export const SetAppTheme = (theme) => ({
  type: ApplicationActionTypes.SetAppTheme,
  theme,
})

export const SetDefaultTheme = (themeName: BuiltInThemes) => (dispatch) => {
  localStorage.setItem('rh24BuiltInTheme', themeName)

  switch (themeName) {
    case 'darkBlue':
      dispatch(SetAppTheme(darkBlueTheme))
      break
    default:
      dispatch(SetAppTheme(rh24WebAppTheme))
  }

  dispatch({
    type: ApplicationActionTypes.SetBuiltInTheme,
    themeName,
  })
}

export const CloseUserFeedbackForm = () => ({
  type: ApplicationActionTypes.CloseUserFeedbackForm,
})
