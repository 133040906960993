import { BomItemPointer } from 'model/Project/BomItemPointer'
import { AssemblyHeaderRow } from 'model/Project/BoMItemRow'
import { RootAppState } from 'store/configureStore'
import { bomItemSelector } from './bomItemSelector'

export const bomItemSearchedSelector = (state: RootAppState) => {
  return state.project.searchedBomItemPointer
}

export const bomItemTypeSearchedSelector = (state: RootAppState) => {
  return state.project.searchedBomItemPointer?.type
}

export const bomItemIsSearchedSelector =
  (bomItemId: string) => (state: RootAppState) => {
    return state.project.searchedBomItemPointer?.id === bomItemId 
  }

export const bomItemSelectedSelector =
  (bomItemId: string) => (state: RootAppState) => {
    return state.project?.selectedBomItemPointers?.find(
      (x) => x.id === bomItemId
    )
  }

export const bomItemIsSelectedSelector =
  (bomItemId: string) => (state: RootAppState) => {
    return (
      state.project?.selectedBomItemPointers?.findIndex(
        (x) => x.id === bomItemId
      ) > -1
    )
  }

export const headerIsSelectedSelector =
  (headerPointer: BomItemPointer) => (state: RootAppState) => {
    if (!state.project?.selectedBomItemPointers?.length) {
      return 'none'
    }

    const header = bomItemSelector(state, headerPointer) as AssemblyHeaderRow

    if (
      header.filteredPartTypePointers.every((pointer) =>
        state.project?.selectedBomItemPointers?.find((x) => x.id === pointer.id)
      )
    ) {
      return 'all'
    }

    if (
      header.filteredPartTypePointers.some((pointer) =>
        state.project?.selectedBomItemPointers?.find((x) => x.id === pointer.id)
      )
    ) {
      return 'some'
    }

    return 'none'
  }

export const bomItemSelectedCounter = (state: RootAppState) => {
  return state.project?.selectedBomItemPointers?.length
}
