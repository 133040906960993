import cadex from '@cadexchanger/web-toolkit'
import { BoundingBoxManager } from 'components/ModelViewers/CadExchangerViewer/InputHandlers/BoundingBoxManager'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import {
  FeatureDto,
  Point3dDto,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import {
  MeasurementMode,
  MeasurementsManager,
} from './InputHandlers/MeasurementsManager'
import { ModelEnvironment } from './ModelEnvironment'
import { ModelFileManager } from './ModelFileManager'
import { ModelPMIDataManager } from './ModelPMIDataManager'
import { ModelData, TreeDataManager } from './TreeDataManager'
import { SceneDataStore } from './store/SceneDataStore'
import { FacesDataVisitor } from './visitors/FacesDataVisitor'
import { ChangeDisplayModeVisitor } from './visitors/ModelChangeDisplayVisitor'
import { SelectedEntityVisitor } from './visitors/SelectedEntityVisitor'

type Events =
  | 'fileLoaded'
  | 'init'
  | 'pmiLoaded'
  | 'dataChanged'
  | 'measurementModeChanged'
  | 'selectionModeChanged'
  | 'displayModeChanged'
  | 'selectionColorChanged'
  | 'selectionChanged'

export class ModelController {
  private Environment: ModelEnvironment
  private FileManager: ModelFileManager
  private subscribers: Partial<
    Record<Events, Array<(...args: unknown[]) => void>>
  >
  private TreeDataManager: TreeDataManager
  public PMIManager: ModelPMIDataManager
  private displayMode: cadex.ModelPrs_DisplayMode
  private displayModeVisitor: ChangeDisplayModeVisitor
  public sceneStore: SceneDataStore
  private measurementManager: MeasurementsManager
  private faceDataRetrieved: boolean
  private bomItemPointer: BomItemPointer
  private boundingBoxManager: BoundingBoxManager

  private _isInitialized: boolean
  modelFeatures: FeatureDto[]

  public get isInitialized() {
    return this._isInitialized
  }

  constructor(bomItemPointer: BomItemPointer) {
    this.subscribers = {
      init: [],
      fileLoaded: [],
      pmiLoaded: [],
      dataChanged: [],
      measurementModeChanged: [],
    }

    const store = new SceneDataStore()

    this.sceneStore = store
    this.displayModeVisitor = new ChangeDisplayModeVisitor()
    this.bomItemPointer = bomItemPointer

    //for debbuging
    // debugAddToWindow('store', this.sceneStore)
  }

  public dispose() {
    if (this.Environment) {
      this.Environment.dispose()
      this._isInitialized = false
      this.faceDataRetrieved = false
    }
  }

  public getScene() {
    return this.Environment.Scene
  }

  public init(htmlElement: HTMLElement): void {
    if (this._isInitialized) {
      this.dispose()
    }

    this.Environment = new ModelEnvironment(
      htmlElement,
      this.sceneStore,
      this.bomItemPointer
    ).init()

    setTimeout(() => {
      this.Environment.Scene.selectionManager.addEventListener(
        'selectionChanged',
        this.onSelectionChangedByTheScene
      )
    })
  }

  private selectedItems: Array<{
    elementId: string | number
    elementType: string
    isManuallyAdded?: boolean
  }> = []

  public onSelectionChangedByTheScene = (
    args: cadex.ModelPrs_SelectionChangedEvent
  ) => {
    if (this.measurementManager) {
      this.measurementManager.checkSelectedItems(args)
    }

    const selectedEntityVisitor = new SelectedEntityVisitor()

    const addedItems: Array<{
      elementId: string | number
      elementType: string
    }> = []

    if (args.removed.length) {
      const removedItems = []
      args.removed.forEach((item) => {
        for (const entity of item.entities()) {
          selectedEntityVisitor.node = item.node
          entity.accept(selectedEntityVisitor)

          removedItems.push({
            elementId: selectedEntityVisitor.shapeId,
            elementType: selectedEntityVisitor.shapeType,
          })
        }

        this.selectedItems = this.selectedItems.filter(
          (selectedItem) =>
            !removedItems.find(
              (removedItem) =>
                removedItem?.elementId?.toString() ===
                selectedItem?.elementId?.toString()
            )
        )
      })
    }

    args.added.forEach((item) => {
      for (const entity of item.entities()) {
        selectedEntityVisitor.node = item.node

        entity.accept(selectedEntityVisitor)

        addedItems.push({
          elementId: selectedEntityVisitor.shapeId,
          elementType: selectedEntityVisitor.shapeType,
        })
      }
    })

    // this.selectedItems.push(...addedItems)

    // when changed by a scene, we need to remove programatically added items
    // (items added when the user selects a feature in the tree for example)
    this.selectedItems = this.selectedItems
      .filter((x) => !x.isManuallyAdded)
      .concat(addedItems)

    this.notifySubscribers('selectionChanged', this.selectedItems)

    // console.log('args', args)
    // args.added.forEach((item) => {
    //   this.notifySubscribers('selectionChanged', {
    //     elementId: item.node.,
    //     elementType: item.node.nodeType,
    //   })
    // })
  }

  public notifySubscribers = (event: Events, ...args: unknown[]) => {
    if (this.subscribers[event]) {
      this.subscribers[event].forEach((callback) => callback(...args))
    }
  }

  private registerSubscriber(callback, event: Events) {
    if (this.subscribers[event]) {
      this.subscribers[event].push(callback)
    } else {
      this.subscribers[event] = [callback]
    }
  }
  /**
   * Registers a callback function that will be called when a file is loaded.
   * If the file is already loaded, the callback function is called immediately.
   * If the file is not yet loaded, the callback function is added to an array of subscribers
   * that will be notified when the file is loaded.
   * @param callback: A callback function that takes no arguments and returns nothing.
   */
  public onFileLoaded(callback: () => void) {
    if (this._isInitialized) {
      callback()
    } else {
      this.registerSubscriber(callback, 'fileLoaded')
    }
  }

  public onPMIDataLoaded(callback: () => void) {
    this.registerSubscriber(callback, 'pmiLoaded')
  }

  public onDataChanged(callback: () => void) {
    this.registerSubscriber(callback, 'dataChanged')
  }

  public onMeasurentModeChanged(callback: (mode: MeasurementMode) => void) {
    this.registerSubscriber(callback, 'measurementModeChanged')
  }

  public onSelectionModeChanged(
    callback: (mode: cadex.ModelPrs_SelectionMode) => void
  ) {
    this.registerSubscriber(callback, 'selectionModeChanged')
  }

  public onDisplayModeChanged(
    callback: (mode: cadex.ModelPrs_DisplayMode) => void
  ) {
    this.registerSubscriber(callback, 'displayModeChanged')
  }

  public onSelectionColorChanged(
    callback: (color: cadex.ModelData_ColorObject) => void
  ) {
    this.registerSubscriber(callback, 'selectionColorChanged')
  }

  public onSelectionChanged(
    callback: (args: { elementId: string; elementType: string }[]) => void
  ) {
    this.registerSubscriber(callback, 'selectionChanged')
  }

  public async LoadPMIData() {
    this.PMIManager = new ModelPMIDataManager(this.Environment, this.sceneStore)

    await this.PMIManager.loadPMIData()
    await this.PMIManager.createPMINodes()

    setTimeout(async () => {
      // for some reason it is not working without timeout
      await this.Environment.Scene.update()
    })

    this.notifySubscribers('pmiLoaded')
  }

  public async LoadPmiView(viewName: string) {
    this.PMIManager.loadPmiView(viewName)

    await this.Environment.Scene.update()
  }

  public async load3DModel(bomItemPointer: BomItemPointer, fileName: string) {
    this.FileManager = new ModelFileManager(this.Environment)

    const loadResult = await this.FileManager.load3DModel(
      bomItemPointer,
      fileName
    )
    await this.Environment.loadModel(loadResult)

    await this.LoadPMIData()

    this._isInitialized = true

    this.notifySubscribers('fileLoaded')
  }

  public async load2DModel(bomItemPointer: BomItemPointer, fileName: string) {
    this.FileManager = new ModelFileManager(this.Environment)

    const loadResult = await this.FileManager.load2DModel(
      bomItemPointer,
      fileName
    )

    await this.Environment.loadModel(loadResult)

    this._isInitialized = true

    this.notifySubscribers('fileLoaded')
  }

  public getDetailedData = async () => {
    if (!this.TreeDataManager) {
      this.TreeDataManager = new TreeDataManager(
        this.Environment,
        this.sceneStore
      )
      return await this.TreeDataManager.ExtractDetailedData()
    } else {
      return await this.TreeDataManager.GetDetailedData()
    }
  }

  public getFaceShapeData = async () => {
    if (!this.faceDataRetrieved) {
      const visitor = new FacesDataVisitor(this.sceneStore)
      await this.Environment.accept(visitor)

      await this.Environment.Scene.update()

      this.notifySubscribers('dataChanged')
      this.faceDataRetrieved = true
    }
  }

  public selectNodesById = async (nodeIds: string[], nodeType: string) => {
    if (nodeIds.length > 100) {
      console.warn('too many nodes to be selected', nodeIds.length)
      return
    }

    this.Environment.Scene.selectionManager.deselectAll()
    let selected = false

    if (nodeType === 'FACE') {
      await this.getFaceShapeData()
    }

    const rh24Nodes = nodeIds
      .map((nodeId) => this.sceneStore.getSceneNodeItem(nodeId))
      .flat()

    if (nodeType === 'FACE') {
      const selectionItems = rh24Nodes
        .map((node) => {
          const shape = node.shape

          if (shape) {
            return new cadex.ModelPrs_SelectionItem(
              node['parentNode'],
              new cadex.ModelPrs_SelectedShapeEntity(shape)
            )
          } else {
            console.error('shape not found')
            return null
          }
        })
        .filter((x) => Boolean(x))

      selectionItems.forEach((item) => {
        selected = this.Environment.Scene.selectionManager.select(
          item,
          false,
          true
        )
      })
    } else {
      rh24Nodes.forEach((node) => {
        selected = this.Environment.Scene.selectionManager.selectNode(
          node,
          false,
          true
        )
      })
    }

    if (selected) {
      this.selectedItems = nodeIds.map((nodeId) => {
        return {
          elementId: nodeId,
          elementType: nodeType,
          isManuallyAdded: true,
        }
      })
    }
  }

  public selectNode = async (modelData: ModelData) => {
    this.Environment.Scene.selectionManager.deselectAll()
    let selected = false

    const nodes = this.sceneStore.getSceneNodeItem(modelData.elementId)

    if (!nodes) {
      console.error('element not found', modelData)
      return
    }

    if (modelData.nodeType === 'FACE') {
      nodes.forEach((node) => {
        const shape = node.shape

        if (shape) {
          const selectionItem = new cadex.ModelPrs_SelectionItem(
            node,
            new cadex.ModelPrs_SelectedShapeEntity(shape)
          )

          selected = this.Environment.Scene.selectionManager.select(
            selectionItem,
            false,
            true
          )
        } else {
          console.error('shape not found')
        }
      })
    } else {
      nodes.forEach((node) => {
        selected = this.Environment.Scene.selectionManager.selectNode(
          node,
          false
        )
      })
    }

    if (!selected) {
      console.error('element not selected', modelData)
    }
  }

  public async highlightNode(modelData: ModelData) {
    const elements = this.sceneStore.getSceneNodeItem(modelData.elementId)

    this.Environment.Scene.selectionManager.unhighlightAll()

    if (elements) {
      if (modelData.nodeType === 'FACE') {
        elements.forEach((element) => {
          const shape = element.shape

          if (!shape) {
            console.error('shape not found')
          } else {
            const selectionItem = new cadex.ModelPrs_SelectionItem(
              element,
              new cadex.ModelPrs_SelectedShapeEntity(shape)
            )

            const highlighted =
              this.Environment.Scene.selectionManager.highlight(selectionItem)

            if (!highlighted) {
              console.error('element not highlighted', element)
            }
          }
        })
      } else {
        elements.forEach((element) => {
          const highlighted =
            this.Environment.Scene.selectionManager.highlightNode(element)

          if (!highlighted) {
            console.error('element not highlighted', element)
          }
        })
      }
    } else {
      console.error('no elements to highlight', modelData.elementId)
    }
  }

  public FitAll() {
    this.Environment.ViewPort.fitAll()
  }

  public async FitPart(modelData: ModelData) {
    const nodes = this.sceneStore.getSceneNodeItem(modelData.elementId)

    let boundingBox: cadex.ModelData_Box = null
    nodes.forEach((n) => {
      for (const child of n.childNodes()) {
        boundingBox = child.geometry?.boundingBox()
      }
    })

    const defaultTransformation = new cadex.ModelData_Transformation()
    const transf = nodes[0].transformation
      ? nodes[0].transformation.copy(defaultTransformation)
      : defaultTransformation.makeIdentity()

    if (nodes[0].nodeType === 'FACE') {
      const shape = nodes[0].shape

      if (shape) {
        const boundingBox = nodes[0].geometry.boundingBox()
        this.Environment.ViewPort.camera.fitBox(boundingBox)
      }
    } else {
      if (boundingBox) {
        this.Environment.ViewPort.camera.rotate(
          boundingBox?.getCenter().x,
          boundingBox?.getCenter().y,
          boundingBox?.getCenter().transformed(transf).z,
          boundingBox?.getCenter()
        )
        this.Environment.ViewPort.camera.set(
          new cadex.ModelData_Point(-0, -1, -1),
          new cadex.ModelData_Point(0, -1000, 0),
          new cadex.ModelData_Direction(0, 1, 0)
        )
      }
    }
  }

  public async TogglePartVisibility(modelData: ModelData) {
    this.sceneStore
      .getSceneNodeItem(modelData.elementId)
      ?.forEach((element) => {
        element.visibilityMode =
          element.visibilityMode === cadex.ModelPrs_VisibilityMode.GhostlyHidden
            ? cadex.ModelPrs_VisibilityMode.Visible
            : cadex.ModelPrs_VisibilityMode.GhostlyHidden
        element.invalidate()
      })

    await this.Environment.Scene.update()
  }

  public async SetSelectionMode(mode: cadex.ModelPrs_SelectionMode) {
    for (const root of this.Environment.Scene.roots()) {
      root.selectionMode = mode
    }

    this.notifySubscribers('selectionModeChanged', mode)

    await this.Environment.Scene.update()
  }

  public getDisplayMode() {
    return this.displayMode
  }

  public async setDisplayMode(mode: cadex.ModelPrs_DisplayMode) {
    if (mode !== this.displayMode) {
      this.displayMode = mode
      this.displayModeVisitor.DisplayMode = mode

      for (const root of this.Environment.Scene.roots()) {
        root.displayMode = mode

        root.accept(this.displayModeVisitor)

        root.invalidate()
      }

      this.notifySubscribers('displayModeChanged', mode, this.displayMode)

      await this.Environment.Scene.update()
    }
  }

  public async setNodeColor(modelData: ModelData) {
    const nodes = this.sceneStore.getSceneNodeItem(modelData.elementId)

    if (nodes) {
      nodes.forEach(async (node) => {
        if (modelData.nodeType === 'FACE') {
          // const changeColorVisitor = new ChangeColorVisitor(node.elementId)

          const rep = node as unknown as cadex.ModelData_BRepRepresentation

          rep?.setShapeAppearance &&
            rep?.setShapeAppearance(
              node.shape,
              new cadex.ModelData_Appearance(
                new cadex.ModelData_ColorObject(1, 0, 0, 1)
              )
            )

          node.invalidate()

          // await node.accept(changeColorVisitor)
        }
      })
    }

    await this.Environment.Scene.update()
  }

  /**
   * explode the current model
   * @param value 0 - 1 number
   */
  public async explode(value: number): Promise<void> {
    this.Environment.ViewPort.exploder.isActive = value > 0
    this.Environment.ViewPort.exploder.value = value
  }

  public async enableDistanceMeasurement() {
    this.resetMeasurement()
    this.measurementManager = new MeasurementsManager(this.Environment.Scene)

    this.measurementManager.setMeasurementMode(MeasurementMode.TwoPointDistance)

    this.SetSelectionMode(cadex.ModelPrs_SelectionMode.Vertex)

    this.AddInputHandler(this.measurementManager)

    this.Environment.HtmlElement.style.cursor = 'crosshair'

    this.notifySubscribers(
      'measurementModeChanged',
      MeasurementMode.TwoPointDistance
    )

    await this.Environment.Scene.update()
  }

  public async enableAngleMeasurement() {
    this.resetMeasurement()

    this.measurementManager = new MeasurementsManager(this.Environment.Scene)
    this.measurementManager.setMeasurementMode(MeasurementMode.ThreePointAngle)

    this.SetSelectionMode(cadex.ModelPrs_SelectionMode.Vertex)

    this.AddInputHandler(this.measurementManager)

    this.Environment.HtmlElement.style.cursor = 'crosshair'

    this.notifySubscribers(
      'measurementModeChanged',
      MeasurementMode.ThreePointAngle
    )

    await this.Environment.Scene.update()
  }

  public async enableFaceMeasurement() {
    this.resetMeasurement()

    this.measurementManager = new MeasurementsManager(this.Environment.Scene)
    this.measurementManager.setMeasurementMode(MeasurementMode.Face)

    this.SetSelectionMode(cadex.ModelPrs_SelectionMode.Face)

    this.Environment.HtmlElement.style.cursor = 'crosshair'

    this.AddInputHandler(this.measurementManager)

    await this.Environment.Scene.update()
  }

  public async addMeasurement(
    point1: cadex.ModelData_Point,
    point2: cadex.ModelData_Point
  ) {
    this.measurementManager = new MeasurementsManager(this.Environment.Scene)

    if (this.measurementManager) {
      this.measurementManager.createDistanceMeasurement(point1, point2)
    }
  }

  public async resetMeasurement() {
    if (this.measurementManager) {
      this.Environment.ViewPort?.inputManager.removeInputHandler(
        this.measurementManager
      )

      this.measurementManager = undefined
      this.Environment.HtmlElement.style.cursor = 'default'
      this.notifySubscribers('measurementModeChanged', MeasurementMode.None)

      await this.SetSelectionMode(cadex.ModelPrs_SelectionMode.Face)
    }
  }

  public async AddInputHandler(handler: cadex.ModelPrs_InputHandler) {
    this.Environment.ViewPort?.inputManager.pushInputHandler(handler)
  }

  public async RemoveInputHandler(handler: cadex.ModelPrs_InputHandler) {
    this.Environment.ViewPort?.inputManager.removeInputHandler(handler)
  }

  public async AddBoundingBox(points: Point3dDto[]) {
    if (!this.boundingBoxManager) {
      try {
        this.boundingBoxManager = new BoundingBoxManager(
          this.Environment.Scene,
          points
        )
      } catch (err) {
        console.error('error creating the bounding box', err)
      }
    }

    await this.boundingBoxManager.createBoundingBox()
  }

  public async ClearBoundingBox() {
    this.boundingBoxManager?.clear()
  }

  private hexToRgb(hex: string): { r: number; g: number; b: number } {
    // Remove the '#' character from the beginning of the string
    hex = hex.replace('#', '')

    // Convert the HEX string to an integer
    const hexInt = parseInt(hex, 16)

    // Extract the red, green, and blue components from the integer
    const r = (hexInt >> 16) & 255
    const g = (hexInt >> 8) & 255
    const b = hexInt & 255

    // Normalize the values to be between 0 and 1
    const rNorm = r / 255
    const gNorm = g / 255
    const bNorm = b / 255

    // console.log('from', hex, 'to', { r: rNorm, g: gNorm, b: bNorm })

    // Return an object with the RGB properties
    return { r: rNorm, g: gNorm, b: bNorm }
  }

  public async SetSelectionColor(color: string) {
    const rgb = this.hexToRgb(color)

    this.notifySubscribers(
      'selectionColorChanged',
      new cadex.ModelData_ColorObject(rgb.r, rgb.g, rgb.b, 1)
    )
  }

  public async updateScene() {
    return this.Environment.Scene.update()
  }

  SetModelFeatures(features: FeatureDto[]) {
    this.modelFeatures = features
  }
}
